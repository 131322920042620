@import "../../../styles/inspinia/variables";
@import "../../../styles/helpers";

.tag-input-holder {
    position: relative;
    min-height: 50px;
    .tag-input-group {
        max-width: 100%;
        width: 420px;

        .tag-input-placeholder {
            position: relative;
            padding: 12px 17px;
            border: 2px solid $blue;
            border-radius: 5px;
            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: $blue;
                & + i {
                    position: absolute;
                    top: 17px;
                    right: 20px;
                    color: $blue;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }
        .tag-list-container {
            box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
            border: 1px solid $gray-blue;
            border-radius: 5px;
            .tag-input-container {
                display: flex;
                flex-wrap: wrap;
                min-height: 45px;
                width: 100%;
                border-bottom: 1px solid $gray-blue;
                align-items: center;
                padding: 10px 10px 0 10px;
                .tag-input {
                    display: flex;
                    margin-left: 5px;
                    border: 0;
                    background: transparent;
                    width: auto;
                    height: 45px;
                    padding-left: 2px;
                    padding-top: 5px;
                    padding-bottom: 16px;

                    &:focus {
                        outline: 0;
                    }
                }
                .tag {
                    display: flex;
                    padding: 5px 11px;
                }
            }
            .tag-list {
                padding: 0;
                max-height: 255px;
                overflow: auto;
                border-bottom: 1px solid $gray-blue;
                li {
                    display: block;
                    width: 100%;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    padding: 12px 32px 12px 47px;
                    color: $medium-blue;
                    &:hover {
                        cursor: pointer;
                        background: $light-gray;
                    }

                    &.active {
                        color: $light-blue;
                    }
                }
            }
        }
        &.open {
            position: absolute;
            z-index: 10000;
            background: $white;
        }
    }

}
.tag {
    display: inline-block;
    max-height: 25px;
    padding: 3px 11px;
    background: $yellow;
    color: $dark-blue;
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 10px;
    .title {
        padding: 0;
        margin: 0;
        font-weight: bold;
        font-size: 12px;
        line-height: 15px;
    }
    .btn {
        background-color: transparent;
        padding: 0;
        margin-left: 9px;
        font-size: 12px;
        line-height: 15px;
    }
}
