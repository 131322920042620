@import "../../styles/inspinia/variables";
@import "../../styles/inspinia/mixins";

.link-group {
    & + .link-group {
        margin-top: 60px;
        padding-top: 20px;
        border-top: 1px solid $gray-blue;
    }

    h3 {
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        color: $blue;

        &:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f07b";
            font-size: 18px;
            padding-right: 5px;
            z-index: 1;
            position: relative;
            color: $dark-blue;
        }
    }

    ul {
        margin: 20px 0 20px 6px;
        padding: 0;
        list-style-type: none;
        position: relative;

        &:before {
            position: absolute;
            display: block;
            content: "";
            background: $gray-blue;
            top: 0;
            left: 0;
            bottom: 0;
            width: 1px;
            margin-top: -25px;
        }

        li {
            position: relative;
            margin: 0;
            padding: 0;
            list-style-type: none;

            &:after {
                position: absolute;
                display: block;
                content: "";
                background: $gray-blue;
                top: 21px;
                left: 0;
                bottom: 21px;
                height: 1px;
                width: 7.5px;
                transition: all 0.2s ease-in-out;
            }

            a {
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                display: inline-block;
                padding: 12px 14px;
                opacity: 0.7;
                color: $medium-blue;
                transition: all 0.2s ease-in-out;

                @include breakpoint(xlarge) {
                    font-size: 15px;
                }
            }

            &:first-of-type {
                a {
                    padding-top: 0;
                }

                &:after {
                    top: 9px;
                }
            }

            &:last-of-type {
                a {
                    padding-bottom: 0;
                }

                &:after {
                    bottom: 0;
                    border-bottom: 10px solid $white;
                    background: $white;
                    border-top: 1px solid $gray-blue;
                    height: unset;
                }
            }

            &.directory {
                &.open {
                    > a.has-children {
                        font-weight: 700;
                        color: $blue;
                        opacity: 1;
                        &:after {
                            background: url(/images/angle-up.svg);
                        }
                    }
                }
                &.active {
                    &:before {
                        content: "\f65d";
                        color: $medium-blue;
                        opacity: 0.7;
                    }

                    a {
                        &.has-children {
                            &:after {
                                background: url(/images/angle-up.svg);
                            }
                        }
                    }
                }

                a {
                    padding-left: 5px;

                    &.has-children {
                        &:after {
                            width: 11px;
                            height: 7px;
                            background: url(/images/angle-down.svg);
                            background-size: cover;
                            margin: 7px 0;
                            content: "";
                            position: absolute;
                            right: 0;
                        }
                    }
                }

                &:before {
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f65e";
                    font-size: 15px;
                    margin-left: 8px;
                    z-index: 1;
                    position: relative;
                }

                &.open {
                    &:before {
                        content: "\f65d";
                        color: $medium-blue;
                        opacity: 0.7;
                    }
                }

                ul.subdir {
                    &:after {
                        content: none;
                    }
                }
            }

            a:hover {
                font-weight: 700;
                color: $blue;
                opacity: 1;
            }

            &.active {
                > a {
                    font-weight: 700;
                    color: $blue;
                    opacity: 1;
                }

                > a,
                > a:hover {
                    cursor: default;
                }
            }

            &:hover,
            &.active {
                &:after {
                    width: 7.5px;
                }
            }

            ul.subdir {
                display: none;
                margin: 12px 0 0px 15px;

                &:before {
                    margin-top: -18px;
                }

                &.open {
                    display: block;
                }

                li {
                    &.open.directory {
                        a {
                            font-weight: 700;
                            color: $blue;
                            opacity: 1;
                        }
                    }

                    padding-left: 8px;

                    &:after {
                        height: 1px;
                    }
                }
            }
        }
    }
}
.side-bar-node {
    .link-group {
        padding: 0 22px;
        ul {
            &:before {
                background: $dark-blue03;
            }
            li {
                ul.subdir {
                    li {
                        padding: 6px 8px;
                        &:last-of-type:after {
                            border-bottom: 15px solid$dark-gray;
                        }
                        &:after {
                            top: 16px;
                        }
                    }
                }
                &:after {
                    top: 10px;
                    background: $dark-blue03;
                }
                &:last-of-type {
                    &:after {
                        background: $dark-gray;
                        border-top: 1px solid $dark-blue03;
                        border-bottom: 10px solid $dark-gray;
                    }
                }
            }
        }
    }
}
