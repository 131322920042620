@import "../../styles/inspinia/variables";

.education-module-list-page {
    .table-row {
        .actions {
            opacity: 0.3;
        }
        &:hover {
            .actions {
                opacity: 1;
            }
            background: $white;
            border: 1px solid $gray-blue;
            box-sizing: border-box;
            box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
            border-radius: 5px;
        }
    }
}