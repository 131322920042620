@import "../../styles/inspinia/variables";

.faq-create-button {
    button {
        padding-left: 25px !important;
        padding-right: 25px !important;
    }
    span {
        margin-right: 10px !important;
    }
}

.faq {
    .supporter-content {
        .table-cell {
            padding: 20px;
        }
    }
    .admin-content {
        .table-row {
            .actions {
                display: flex;
                justify-content: flex-start;
            }
            .position {
                display: flex;
                padding-left: 30px;
            }
            .position-icon {
                display: none;
            }
            &:hover {
                background: $white;
                border: 1px solid $gray-blue;
                box-sizing: border-box;
                box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
                border-radius: 5px;
                z-index: 1;
                .action-buttons {
                    visibility: visible;
                }
                .position-number {
                    display: none;
                }
                .position-icon {
                    display: flex;
                    flex-direction: column;
                    color: $medium-blue;
                    opacity: 0.5;
                    font-size: 20px;
                }
            }
            .action-buttons {
                display: flex;
                visibility: hidden;
                justify-content: flex-end;
                padding-left: 5px;
                padding-right: 5px;

                button {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }
    .table-row:first-of-type {
        border-top: 1px solid $border-color;
    }
    .toggler {
        position: relative;
        color: $dark-blue;
        font-weight: 600;
        padding: 14px 20px 14px 60px;
        min-height: 48px;
        opacity: 0.3;

        i {
            position: absolute;
            top: 0;
            left: 22px;
            bottom: 0;
            margin: auto;
            opacity: 0.5;
            height: 20px;
            font-size: 20px;
            transition: all 0.2s ease-in-out;
        }

        &::after {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            border: none;
            content: "\f078";
            font-size: 12px;
            color: $dark-blue;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 15px;
            margin: auto;
            width: 20px;
            height: 18px;
            transform: rotate(0);
            transform-origin: center;
            transition: transform 0.2s ease-in-out;
            text-align: center;
        }

        &.opened {
            &::after {
                transform: rotate(180deg);
                color: $blue;
            }
            opacity: 1;
        }

        &:hover {
            color: $light-blue;
            cursor: pointer;
            i {
                opacity: 1;
            }
        }
    }
    .question-line {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
    }
    .answer-cell {
        flex: 1;
        padding: 20px;
        align-items: center;
        display: flex;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $dark-blue;

        background: $white;
        border-right: 1px solid $gray-blue;
        border-bottom: 1px solid $gray-blue;
        border-left: 1px solid $gray-blue;
        box-sizing: border-box;
        box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .opened-line {
        background: $white;
        border-top: 1px solid $gray-blue;
        border-right: 1px solid $gray-blue;
        border-left: 1px solid $gray-blue;
        box-sizing: border-box;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }
    .table-row:first-of-type.opened-line {
        border-top: 1px solid $border-color;
        border-right: 1px solid $gray-blue;
        border-left: 1px solid $gray-blue;
    }
}
