@import "../../../styles/inspinia/variables";

.client-event-log-table {
    button {
        text-transform: inherit !important;
        font-size: 16px !important;
    }
    .inputs {
        background: $white;
        padding: 36px 53px 30px 53px;
        box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
        border-radius: 5px;
    }
    .section-title {
        margin-left: 15px;
    }
    .client-event-log-table-inner-buttons {
        display: flex;
        justify-content: flex-end;
        :nth-child(2) {
            margin-left: 35px;
        }
    }
    .client-event-log-table-outer-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 70px;
        margin-bottom: 45px;
    }
    .client-event-log-table-input-label {
        margin-top: 12px;
        margin-bottom: 12px;
    }
    .select {
        margin-bottom: 26px;
    }
    .client-event-log-table-date-input {
        width: 200px;
    }
    .client-event-log-table-hour-input {
        width: 134px;
        input {
            border-radius: 5px;
            font-weight: 500;
        }
        .select-value {
            height: 50px;
            line-height: 26px;
        }
    }
    .client-event-log-table-time-inputs {
        display: flex;
        justify-content: space-between;
    }
    .client-event-log-table-dummy-text-container {
        display: flex;
        padding-top: 55px;
        width: 100px;
        justify-content: space-around;
    }
    input:disabled {
        width: 100% !important;
    }
}