@import "../../styles/inspinia/variables";

.everyday-situation-list-item {
    .thumbnail-container {
        position: relative;
        margin-left: 11px;
        margin-right: 31px;

        img {
            width: 50px;
            height: 50px;
        }
    }
    .edit-mode-content, .edit-mode-section {
        // text-align: right;
    }
    .readonly-mode-content {
        align-items: center;
    }
    .title {
        font-weight: bold;
        font-size: 18px;
        color: $blue;
        align-self: center;
    }
    .action-buttons {
        align-items: center;
    }
    .button-container {
        text-align: right;
    }
}
