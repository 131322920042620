@import "../../styles/inspinia/variables";

.education-sub-module-form {
    .education-sub-module-form-lead {
        margin-bottom: 50px;
    }
    .education-sub-module-form-document-title-cell {
        display: flex;
        align-items: center;
        .education-sub-module-form-document-title-cell-title, .education-sub-module-form-document-title-cell-progress {
            margin-left: 35px;
        }
    }
    .education-sub-module-form-document-action-buttons, .education-sub-module-form-video-action-buttons {
        display: flex;
    }
    .videos-section {
        .title {
            flex: 0 1 !important;
            width: 400px !important;
        }
    }
    .video-image-container {
        position: relative;
        width: 100%;
        width: 140px;
        height: 140px;
    }
    .video-image {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }
    .video-image-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: .3s ease;
        background-color: $black;
        border-radius: 5px;
    }
    .education-sub-module-form-video-action-buttons-container {
        .progress {
            margin-bottom: 0;
            background-color: $white;
        }
    }
    .education-sub-module-form-document-title-cell-text {
        display: flex;
        flex-direction: column;
    }
}
