/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.8
 *
*/

// Google Fonts
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&display=swap&subset=latin-ext");

// Glyphicons from Bootstrap 3.3.7
// @import "glyphicons.scss";
@import "fontawesome.scss";

// Variables, Mixins
@import "variables.scss";
@import "mixins.scss";

// INSPINIA Theme Elements
@import "typography.scss";
@import "navigation.scss";
@import "top_navigation.scss";
@import "buttons.scss";
@import "badges_labels.scss";
@import "elements.scss";
@import "sidebar.scss";
@import "base.scss";
@import "pages.scss";
@import "chat.scss";
@import "metismenu.scss";
@import "spinners.scss";

// INSPINIA Skins
@import "skins.scss";

// Media query style
@import "media.scss";

// Custom style
// Your custom style to override base style
@import "custom.scss";

// Supervisor Skin
@import "supervisor.scss";

// Clear layout on print mode
@media print {
    nav.navbar-static-side {
        display: none;
    }

    body {
        overflow: visible !important;
    }

    #page-wrapper {
        margin: 0;
    }
}

// Note: https://css-tricks.com/sass-style-guide/#shame-last
@import "shame.scss";
