@import "../../styles/inspinia/variables";
@import "../../styles/inspinia/mixins";

.default-agenda {
    .table-image-name {
        display: flex;
        align-items: center;
    }

    .table-image-container {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        margin-right: 15px;

        @include breakpoint(xlarge) {
            width: 50px;
            height: 50px;
        }

        img {
            width: 100%;
            height: 100;
            display: block;
            position: absolute;
            object-fit: cover;
            object-position: center;
        }
    }
}
