@import "../../styles/inspinia/variables";

.alert-layer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: -1;

    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

    .alert-touch-layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .alert-window {
        position: absolute;
        background: #fff;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -40%, 0);
        width: 800px;
        border-radius: 5px;
        transition: transform 0.3s ease-in-out;

        &.alert {
            width: 600px;
            text-align: center;
            padding: 40px;
            img {
                margin-bottom: 40px;
                width: 90px;
                height: 90px;
            }
            p {
                margin: 0;
                padding: 0;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    .alert-window.alert p {
        color: $blue;
    }
    &.success .alert-window.alert {
        p {
            color: $success;
        }
    }
    &.has-error .alert-window.alert {
        p {
            color: $error;
        }
    }

    hr {
        background: $gray-blue;
        height: 1px;
        border: 0;
        padding: 0;
        width: 100%;
        margin: 20px 0;
    }

    &.show {
        opacity: 1;
        pointer-events: all;
        z-index: 100001;

        .alert-window {
            transform: translate3d(-50%, -50%, 0);
        }
    }
}
