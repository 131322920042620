@media (max-width: 1000px) {
    .welcome-message {
        display: none;
    }
}

html {
    box-sizing: border-box;
    font-size: 16px;
    margin: 0;
    padding: 0;

    *::-webkit-scrollbar-track {
        background-color: $light-gray;
    }

    *::-webkit-scrollbar {
        width: 10px;
        background-color: $light-gray;
    }

    *::-webkit-scrollbar-thumb {
        background-color: $light-blue;
        border-top: 10px solid $light-gray;
        border-bottom: 10px solid $light-gray;
        // Note: BorderWidths + width
        min-height: (3 * 10) * 1px;
    }

    &:hover {
        *::-webkit-scrollbar-track {
            background-color: $light-gray;
        }
        *::-webkit-scrollbar {
            width: 10px;
        }
        *::-webkit-scrollbar-thumb {
            background-color: $light-blue;
        }
    }
}

body {
    font-family: "Quicksand", sans-serif;
    background: $medium-blue;
    color: $medium-blue;
    margin: 0;

    &.hide-recaptcha-badge {
        .grecaptcha-badge {
            visibility: collapse !important;
        }
    }
}

ul {
    &.ul-reset-style {
        list-style-type: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;
    }
}

.task {
    margin-bottom: 15px;
}

.align-middle {
    align-items: center;
}

.cell {
    flex: 0 0 auto;
    min-height: 0px;
    min-width: 0px;
    width: 100%;

    &.auto {
        flex: 1 1 0px;
        width: auto;
    }

    &.delete {
        width: 60px;
    }

    &.shrink {
        flex: 0 0 auto;
        width: auto;
    }

    &.small-4 {
        width: 33.333%;
    }

    .align-middle {
        align-items: center;
    }

    @include breakpoint(medium) {
        &.medium-1 {
            width: 8.333%;
        }
        &.medium-2 {
            width: 16.666%;
        }
        &.medium-3 {
            width: 25%;
        }
        &.medium-4 {
            width: 33.333%;
        }
        &.medium-5 {
            width: 41.666%;
        }
        &.medium-6 {
            width: 50%;
        }

        &.medium-7 {
            width: 58.333%;
        }

        &.medium-8 {
            width: 66.666%;
        }

        &.medium-10 {
            width: 83.333%;
        }

        &.medium-auto {
            flex: 1 1 0px;
            width: auto;
        }

        &.medium-shrink {
            flex: 0 0 auto;
            width: auto;
        }
    }

    @include breakpoint(large) {
        &.large-auto {
            flex: 1 1 0px;
            width: auto;
        }

        &.large-shrink {
            flex: 0 0 auto;
            width: auto;
        }
    }

    @include breakpoint(xlarge) {
        &.xxlarge-5 {
            width: 41.66%;
        }

        &.xxlarge-7 {
            width: 58.33%;
        }
    }
}

.unit {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: $medium-blue;
}

.grid-x.padded {
    margin-left: -10px;
    margin-right: -10px;

    @include breakpoint(xlarge) {
        margin-left: -15px;
        margin-right: -15px;
    }

    .cell {
        box-sizing: border-box;
        padding-left: 10px;
        padding-right: 10px;

        @include breakpoint(xlarge) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .timeInput {
        width: calc(50% - 50px);
        @include breakpoint(xlarge) {
            width: calc(50% - 90px);
        }
    }
    .timeUnit {
        width: 50px;
        @include breakpoint(xlarge) {
            width: 90px;
        }
    }
}

.scrollable {
    height: calc(100vh - 84px);
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

a {
    color: $blue;
    outline-color: transparent;

    &:active,
    &:hover {
        color: $light-blue;
        text-decoration: none;
    }
}

header,
main,
video {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin-top: 0;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
    margin-bottom: 30px;
}

h1 {
    color: $blue;
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
}

h2 {
    color: $blue;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    padding: 0 0 20px 0;
    margin: 0 0 30px 0;
    text-transform: uppercase;
    border-bottom: 1px solid $title-border;
}

h3 {
    margin: 30px 0;
    padding: 0;
    color: $medium-blue;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
}

p {
    color: $dark-blue;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    &.lead {
        font-weight: 500;
        margin: 40px 0;
        font-size: 16px;
        line-height: 20px;
        max-width: 720px;
    }

    &.no-max-width {
        max-width: 100%;
    }
}

.usage {
    border-top: 1px solid $gray-blue;
    margin: 60px 0 0 0;
    padding: 20px 0 0 0;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: $light-blue;
}

.grid-x {
    display: flex;
    flex-flow: row wrap;

    &.align-justify {
        justify-content: space-between;
    }
}

.show-for-sr {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
}
.button-cell {
    width: 150px;
    text-align: right;

    @include breakpoint(xlarge) {
        width: 200px;
    }
}
.value-cell {
    padding: 15px 20px;
    display: inline-block;
    font-size: 16px;
    height: 16px;
    line-height: 1;
}

.input-password-wrapper {
    position: relative;
    input {
        padding-right: 46px;
    }
    .button-wrapper {
        bottom: 0;
        position: absolute;
        right: 0;
        top: 0;

        button {
            height: 34px;
            position: absolute;
            bottom: 0;
            top: 0;
            right: 0;
            margin: auto;
        }
    }
}

.error-wrapper {
    color: $error;
    display: block;
    font-size: 13px;
    line-height: 16px;
    padding-top: 5px;
    font-weight: 700;
}

.navbar {
    .btn-link {
        color: #fff;

        &:hover {
            color: $light-blue;
        }
    }
}
#side-menu {
    * {
        user-select: none;
    }
}

#side-menu > li,
#side-menu > li > a #side-menu .toggler {
    text-transform: uppercase;
    display: block;
    box-sizing: border-box;
    width: 100%;

    .profile {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: middle;
        margin-right: 14px;
        margin-left: -8px;

        img {
            display: block;
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
        }
    }

    li,
    li > a {
        text-transform: none;
        display: block;
        box-sizing: border-box;
        width: 100%;
    }
    .nav-label {
        display: inline-block;
        vertical-align: middle;
        white-space: normal;
        width: calc(100% - 50px);
    }
}
#side-menu > li {
    background: $medium-blue;

    .nav-second-level {
        background: $dark-blue;
        li {
            padding-bottom: 8px;
            padding-top: 8px;

            &:first-child {
                padding-top: 14px;
            }

            &:last-child {
                padding-bottom: 14px;
            }

            a {
                position: relative;
                color: #fff;
                padding-left: 26px;
                transition: all 0.2s ease-in-out;

                &:before {
                    position: absolute;
                    display: block;
                    content: "";
                    top: -5px;
                    bottom: -5px;
                    left: 0;
                    width: 3px;
                    background-color: rgba($light-blue, 0);
                    transition: background-color 0.2s ease-in-out;
                }

                i {
                    opacity: 0.5;
                    transition: none;
                }
            }
            &:hover,
            &.active {
                background: none;
                a {
                    color: $light-blue;
                    i {
                        opacity: 1;
                        transition: none;
                    }
                }
            }
            &.active {
                a {
                    &:before {
                        background-color: rgba($light-blue, 1);
                    }
                }
            }
        }
    }
}

.nav > li > a i {
    margin-right: 20px;
    opacity: 0.5;
    margin-left: -4px;
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    text-align: center;

    &:before {
        font-size: 16px;
        line-height: 16px;
    }
}

#side-menu > li.active {
    border: none;
    background: #fff;

    .toggler {
        &::after,
        i,
        .nav-label {
            color: $blue;
        }
    }

    a {
        &:hover {
            color: $light-blue;
        }
    }

    i {
        opacity: 1;
    }

    li {
        background: $dark-blue;

        a {
            &:hover {
                color: $light-blue;
            }
        }
    }
}

#page-wrapper {
    z-index: 2;
}

#page-wrapper .header {
    position: relative;
    margin-left: 0;
    z-index: 3;

    @include breakpoint(tablet) {
        margin-left: -220px;
    }
}
.navbar-fixed-top,
.navbar-static-top {
    background: $blue;
}
.navbar-static-side {
    top: 70px;
    z-index: 1;

    .sidebar-collapse {
        height: calc(100vh - 70px);
        overflow-y: auto;
        overflow-x: hidden;
        box-sizing: border-box;
        padding-bottom: 60px;
    }
}

.navbar .version {
    padding: 30px 0 0 0;
    flex: 1;
    span {
        background: rgba(#fff, 0.6);
        font-size: 12px;
        display: inline-block;
        padding: 1px 5px;
        line-height: 14px;
        font-weight: 600;
        vertical-align: middle;
    }
}

.navbar .logo a {
    color: #fff;
    font-size: 12px;
    display: inline-block;
    padding: 8px;
    line-height: 14px;
}
.navbar .logo a span,
.navbar .logo a img {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
}
.profile-menu {
    float: right;
    padding: 0 25px 0 0;
    margin: 17px 20px 0 0;
    border-right: 1px solid rgba($color: #fff, $alpha: 0.5);
    height: 38px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
        display: flex;
        align-items: center;
    }
    .name {
        &,
        span {
            margin-left: 0;
        }
    }
}

.navbar {
    .dropdown-menu {
        margin-top: 15px;
        border-radius: 0;
        background: $dark-blue;
        padding: 10px 0;
        margin-right: -28px;
        box-shadow: 0px 10px 20px rgba($medium-blue, 0.15);

        &::after {
            position: absolute;
            bottom: 100%;
            right: 32px;
            display: block;
            content: "";
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid $dark-blue;
        }

        div,
        a {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            padding: 10px 20px;
            color: #fff;

            &:hover {
                background: rgba(#fff, 0.1);
                color: $light-blue;
                cursor: pointer;
            }
        }
    }
}
.nav > li > a {
    color: #fff;
}
.navbar-default .nav > li > a:hover {
    background: none;
    color: $light-blue;

    i {
        opacity: 1;
    }
}

.navbar-default .nav > li > a:focus {
    //background: none;
    //color: $light-blue;
}

.navbar-default .nav.nav-second-level > li.active > a {
    background: none;
}

.navbar-default .nav > li.active > a {
    background: #fff;
    color: $blue;
}

#side-menu .toggler {
    position: relative;
    color: #fff;
    font-weight: 600;
    padding: 14px 20px 14px 60px;
    min-height: 48px;

    i {
        position: absolute;
        top: 0;
        left: 22px;
        bottom: 0;
        margin: auto;
        opacity: 0.5;
        height: 20px;
        font-size: 20px;
        transition: all 0.2s ease-in-out;
    }

    .profile {
        position: absolute;
        top: 0;
        left: 15px;
        bottom: 0;
        margin: auto;
        transition: all 0.2s ease-in-out;
    }

    &::after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        border: none;
        content: "\f078";
        font-size: 12px;
        color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 15px;
        margin: auto;
        width: 20px;
        height: 18px;
        transform: rotate(0);
        transform-origin: center;
        transition: transform 0.2s ease-in-out;
        text-align: center;
    }

    &.toggled {
        &::after {
            transform: rotate(180deg);
        }
        i {
            opacity: 1;
        }
    }

    &:hover {
        color: $light-blue;
        cursor: pointer;
        i {
            opacity: 1;
        }
    }
}

.react-tabs {
    background-color: #fff;

    .react-tabs__tab-list {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        list-style-type: none;
        margin-bottom: 0;
        margin-top: 0;
        padding-left: 0;

        .react-tabs__tab {
            flex: 0 0 auto;
            min-height: 0px;
            min-width: 0px;
            outline-color: transparent;
            position: relative;
            user-select: none;
            width: auto;
            height: 50px;
            min-width: 200px;
            display: flex;
            align-items: center;
            justify-content: center;

            > a {
                color: $blue;
                display: inline-block;
                font-size: 18px;
                font-weight: 700;
                line-height: 25px;
                padding: 12px 16px;
                text-decoration: none;
            }

            &.is-selected {
                &::after {
                    background-color: $blue;
                    border-radius: 2px;
                    bottom: 0;
                    content: "";
                    height: 4px;
                    left: 0;
                    position: absolute;
                    right: 0;
                    pointer-events: none;
                }
            }

            @include breakpoint(medium) {
                > a {
                    font-size: 25px;
                    line-height: 34px;
                    padding: 10px 25px;
                }

                &:active,
                &:hover {
                    &::after {
                        background-color: $blue;
                        border-radius: 2px;
                        bottom: 0;
                        content: "";
                        height: 4px;
                        left: 0;
                        position: absolute;
                        right: 0;
                        pointer-events: none;
                    }
                }
            }
        }
    }
    .react-tabs__tab-panel {
        background-color: #fff;

        &.is-selected {
            //empty
        }
    }
}

main.guest-page {
    padding: 17px 16px 48px;

    @include breakpoint(large) {
        margin-left: auto;
        margin-right: auto;
        max-width: 876px;
        padding-top: 80px;
    }

    .react-tabs {
        @include breakpoint(large) {
            border-radius: 5px;
            border: 2px solid $blue;
            padding-top: 30px;
        }
    }

    form {
        padding: 12px;
        padding-top: 40px;

        @include breakpoint(large) {
            padding: 40px 77px;
        }
    }

    .lead {
        color: $medium-blue;
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 21px;
    }

    .button-wrapper {
        border-top: 1px solid $blue;
        padding-top: 40px;
        text-align: center;

        a {
            font-weight: 600;
        }

        @include breakpoint(large) {
            text-align: right;

            > .grid-x {
                align-items: center;
            }
        }
    }

    .large-offset {
        @include breakpoint(large) {
            margin-left: 36.5%;
        }
    }

    .icon-wrapper {
        text-align: center;
    }
}
.guest-page-landing__absolute-wrapper {
    bottom: 0;
    left: 0;
    overflow: auto;
    position: absolute;
    right: 0;
    // Note: top should be .navbar's height.
    top: 70px;
    z-index: 1;
}
.guest-page-landing__background {
    background-color: $white;
    background-image: url("/images/guest-page-landing/guest-page-landing-bg.png");
    background-position: center -70px;
    background-repeat: no-repeat;
    position: relative;

    &::before {
        background-image: url("/images/guest-page-landing/infoblokk_1@2x.png");
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 0;
        content: "";
        height: (482 / 2) * 1px;
        pointer-events: none;
        position: absolute;
        right: 0;
        width: (706 / 2) * 1px;
        z-index: 1;
    }
}
.guest-page-landing__container {
    margin-left: auto;
    margin-right: auto;
    max-width: 840px;
    padding-bottom: 40px;
    padding-top: 70px;

    .react-tabs {
        margin-bottom: 60px;

        .react-tabs__tab-panel {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}
.guest-page-landing__h1 {
    border-bottom: 1px solid;
    color: $blue;
    font-size: 26px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    max-width: 565px;
    padding-bottom: 15px;
    text-align: center;
}
.guest-page-landing__h2 {
    border-bottom: 0;
    color: $blue;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 40px;
    margin-top: 0;
    padding-bottom: 0;
    text-align: center;
    text-transform: uppercase;
}
.guest-page-landing__lead {
    font-family: "Open Sans", helvetica, arial, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 20px;
    text-align: center;
}
.guest-page-landing__info {
    font-family: "Open Sans", helvetica, arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 20px;
    text-align: center;
}
.guest-page-landing__footer-text {
    font-family: "Open Sans", helvetica, arial, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 150px auto 0;
    max-width: 420px;
    text-align: center;
}
.guest-page-landing__list {
    li {
        margin-bottom: 60px;
        padding-left: 30px;
        padding-right: 30px;
    }
}
.guest-page-landing__list--translatex {
    transform: translateX(-23px);
}
.guest-page-landing__list-image--h50 {
    height: 50px;
    width: auto;
}
.guest-page-landing__list-image--h100 {
    height: 100px;
    width: auto;
}
nav.navbar-static-side {
    li a,
    .toggler {
        white-space: nowrap;
    }

    transition: width 0.2s ease-in-out;

    & + #page-wrapper {
        transition: margin 0.2s ease-in-out;

        .header {
            transition: margin 0.2s ease-in-out;
        }
    }

    .navbar-toggler {
        position: absolute;
        bottom: 10px;
        right: 10px;
        padding: 0;
        background: none;

        .btn {
            padding: 10px 13px;
            border-radius: 50%;
            background: rgba(#fff, 0.8);
            transform: rotate(0);
            transition: all 0.2s ease-in-out;

            span {
                margin: 0 0 0 0;
            }
            &:hover {
                background: rgba(#fff, 1);
            }
        }
    }

    .nav > li > a,
    #side-menu > li .nav-second-level li a,
    #side-menu .toggler {
        transition: all 0.2s ease-in-out;
    }
    &.collapsed {
        width: 75px;

        .navbar-toggler {
            .btn {
                transform: rotate(180deg);
            }
        }

        .nav > li > a,
        #side-menu > li .nav-second-level li a {
            padding-left: 30px;
        }

        #side-menu .toggler {
            padding-left: 29px;
            .profile {
                left: 20px;
            }
            i {
                left: 26px;
            }
        }

        #side-menu > li .nav-label,
        #side-menu > li .toggler:after {
            display: none;
        }

        & + #page-wrapper {
            margin-left: 75px;

            .header {
                margin-left: -75px;
            }

            .info-bar {
                left: 75px;
            }
        }
    }
}
#page-wrapper,
.wrapper-content {
    padding: 0;
    .header {
        z-index: 30000;
    }
}
.subpage {
    background: #fff;

    .cell:not(.doEdit) {
        .react-datepicker__input-container {
            position: relative;

            &:hover {
                cursor: pointer;
            }

            &::after {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                position: absolute;
                display: block;
                content: "\f073";
                line-height: 40px;
                font-size: 26px;
                top: 4px;
                bottom: 4px;
                right: 4px;
                width: 30px;
                color: $light-blue;
                pointer-events: none;
            }
        }
    }

    .react-datepicker {
        box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
        border: 0;

        &,
        & * {
            font-family: "Quicksand", sans-serif;
        }
    }
    .react-datepicker-popper {
        z-index: 10;
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
        border-bottom-color: $blue;
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
        margin-top: -7px;
    }
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
        width: 100%;
    }
    .react-datepicker__header {
        background-color: $blue;
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        color: #fff;
        font-size: 18px;
    }
    .react-datepicker__day-name {
        color: #fff;
        font-size: 14px;
    }

    .react-datepicker__day,
    .react-datepicker__time-name {
        color: $dark-blue;
        font-size: 14px;
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected {
        border-radius: 50%;
        background-color: $blue;
        color: #fff;

        &:hover {
            background-color: $light-blue;
        }
    }
    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled {
        color: $light-blue;
    }
    .react-datepicker__year-select,
    .react-datepicker__month-select {
        border: 1px solid rgba(#fff, 0.5);
        background: $blue;
        color: #fff;
        font-size: 14px;
        border-radius: 4px;
        padding: 3px 24px 3px 10px;
        margin: 10px 2px;
        -moz-appearance: none;
        -webkit-appearance: none;

        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48cGF0aCBkPSJNMjA3LjAyOSAzODEuNDc2TDEyLjY4NiAxODcuMTMyYy05LjM3My05LjM3My05LjM3My0yNC41NjkgMC0zMy45NDFsMjIuNjY3LTIyLjY2N2M5LjM1Ny05LjM1NyAyNC41MjItOS4zNzUgMzMuOTAxLS4wNEwyMjQgMjg0LjUwNWwxNTQuNzQ1LTE1NC4wMjFjOS4zNzktOS4zMzUgMjQuNTQ0LTkuMzE3IDMzLjkwMS4wNGwyMi42NjcgMjIuNjY3YzkuMzczIDkuMzczIDkuMzczIDI0LjU2OSAwIDMzLjk0MUwyNDAuOTcxIDM4MS40NzZjLTkuMzczIDkuMzcyLTI0LjU2OSA5LjM3Mi0zMy45NDIgMHoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=");
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;
        background-size: 0.65em auto, 100%;

        &:hover {
            cursor: pointer;
        }

        &::-ms-expand {
            display: none;
        }
    }

    .react-datepicker__navigation {
        position: absolute;
        width: 16px;
        height: 20px;
        border: 0;
        padding: 0;
        &:hover {
            border: 0;
        }
    }
    .react-datepicker__navigation--previous:after,
    .react-datepicker__navigation--next:after {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        line-height: 18px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        text-indent: 0;
    }
    .react-datepicker__navigation--previous:after {
        content: "\f053";
    }
    .react-datepicker__navigation--next:after {
        content: "\f054";
    }
    .react-datepicker__navigation:hover:after {
        color: $light-blue;
    }

    .page-header {
        position: relative;
        background: $light-gray;
        box-sizing: border-box;
        height: 70px;
        padding: 20px;
        border-bottom: 1px solid $border-color;

        .buttons {
            position: absolute;
            padding: 0 10px;
            top: 0;
            bottom: 0;
            right: 0;
            line-height: 70px;

            .btn:not(.save):not(.saved) {
                margin: 0 5px;
                padding-left: 5px;
                padding-right: 5px;
                span {
                    margin: 0;
                }
            }

            .badge {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                padding: 5px 4px;
                border-radius: 5px;
                i {
                    margin-right: 8px;
                }
            }
        }

        &.has-back-button {
            a i {
                font-size: 20px;
            }
            h1 {
                margin-left: 30px;
            }
            a,
            h1 {
                display: inline-block;
            }
        }
    }

    .react-datepicker__day.react-datepicker__day--today.react-datepicker__day--selected {
        color: #fff;
    }

    .main-content {
        display: flex;
        margin: auto;
    }

    .side-bar-left {
        width: 200px;
        padding: 20px 10px 20px 20px;
        box-sizing: border-box;
        height: calc(100vh - 140px);
        overflow-y: auto;
        overflow-x: hidden;

        @include breakpoint(xlarge) {
            padding: 20px;
            width: 310px;
        }
    }

    .left-side {
        box-sizing: border-box;
        height: calc(100vh - 140px);
        overflow-y: auto;
        overflow-x: hidden;
        flex: 1;
        padding: 20px 10px 20px 10px;

        &.content-library-table {
            @include breakpoint(xlarge) {
                padding: 20px;
            }
        }

        & > *:not(.modal-layer):not(.fixed-row):not(.row) {
            max-width: 1280px;
            margin-left: auto;
            margin-right: auto;

            &:not(.grid-x) {
                display: block;
            }
        }

        li {
            font-size: 16px;
            color: #031a36;
            font-weight: 500;
            line-height: 20px;
        }

        & > *:last-child {
            margin-bottom: 40px;
        }

        div {
            h2:not(.no-top-margin) {
                margin-top: 20px;
            }
            &.medium-6 {
                h2 {
                    margin-top: 0;
                }
            }
            .right-side {
                h2 {
                    margin-top: 0;
                }
            }
        }
    }

    .right-side {
        width: 290px;
        box-sizing: border-box;
        height: calc(100vh - 140px);
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px;

        @include breakpoint(xlarge) {
            width: 350px;
        }
    }

    &.has-tabs {
        background: $light-gray;

        .main-content {
            max-width: calc(100% + 40px);

            .content-center {
                display: flex;
                max-width: 1280px;
                margin: auto;

                .side-bar-left,
                .left-side,
                .right-side {
                    height: calc(100vh - 280px);
                }
            }
        }
    }

    &.side-bar-view {
        .main-content {
            max-width: 100%;
        }

        .page-header {
            width: calc(100% - 290px);

            @include breakpoint(xlarge) {
                width: calc(100% - 350px);
            }
        }
        .right-side {
            margin-top: -70px;
            height: calc(100vh - 70px);
            background: #e0e9ec;

            h2 {
                padding: 10px 0 10px 0;
                margin: 0 0 15px 0;
            }
        }
    }

    .avatar {
        position: relative;
        width: 188px;
        height: 188px;
        margin: 30px auto;

        &:hover {
            cursor: pointer;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
            -moz-border-radius: 50%;
            -webkit-border-radius: 50%;
            display: block;
        }
        button {
            position: absolute;
            top: 15px;
            right: 15px;
            z-index: 2;
            width: 25px;
            height: 25px;
            text-align: center;
            padding: 0;
            line-height: 4px;
            background: $error;
            border: 2px solid #fff;
            border-radius: 50%;
            color: #fff;
            transform: rotate(0);
            transition: transform 0.3s ease-in-out;

            span {
                margin: 0;
                font-size: 12px;
                line-height: 10px;
            }

            &.blue {
                background: $light-blue;
                &:hover {
                    color: $white !important;
                }
            }

            &:not(.blue):hover {
                transform: rotate(90deg);
                color: #fff;

                span {
                    color: #fff;
                }
            }
        }
        svg {
            position: absolute;
            width: 46px;
            height: 46px;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 2;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
.action-button-container {
    text-align: right;
    margin-bottom: 20px;

    &.table-row {
        margin: 0;
    }

    button {
        & + button {
            margin-left: 20px;
        }
    }
}
/*
form {
  position: relative;

  .edit-button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 30px;
  }
}
*/
.progress {
    height: auto;
}
.progress-bar {
    height: 6px;
    width: 100%;

    &.upload-progress {
        height: 0;
        width: 0;

        &.upload-in-progress {
            height: 6px;
        }
        &.upload-success {
            background-color: $success;
        }
        &.upload-error {
            background-color: $error;
        }
    }
    span {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        overflow: hidden;
        text-indent: -3000px;
        background: $light-blue;
    }
}

.navbar-right,
#page-wrapper .header.row {
    margin-right: 0;
}

.buttons-container {
    text-align: right;
    margin: 20px 0;

    .btn {
        & + .btn {
            margin-left: 20px;
        }
    }
}
.big-font {
    font-size: 26px;
    line-height: 32px;

    strong {
        font-size: 30px;
    }
}
.fixed-row {
    position: fixed;
    bottom: 0;
    left: 220px;
    right: 0;
    background: #fff;
    padding: 20px 0;
    box-shadow: 0px -5px 20px rgba(3, 26, 54, 0.15);
    transition: all 0.2s ease-in-out;
    transform: translate3d(0, 100%, 0);
    opacity: 0;
    margin-bottom: 0 !important;
    z-index: 10;

    &.show {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}
.collapsed + #page-wrapper {
    .fixed-row {
        left: 75px;
    }
}
.side-bar-view {
    .fixed-row {
        right: 296px;

        @include breakpoint(xlarge) {
            right: 356px;
        }
    }
}
.grid-padding-x {
    .cell {
        padding: 0 15px;
    }
}
.extra-margin {
    margin: 30px 0 60px;
}

.basic-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $light-gray;
    border: 1px solid $title-border;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
    min-height: 219px;
    height: 100%;

    & + .basic-box {
        margin-top: 30px;
    }
}

.hidden {
    display: none;
}

.title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;

    @include breakpoint(xlarge) {
        font-size: 16px;
    }

    &.vid {
        margin-top: 10px;
    }
}

.react-player {
    audio {
        background-color: #f3f3f4;
        border-radius: 4px;
        outline: none;
    }

    video {
        max-height: 550px;
    }
}

.video-nav {
    .video-layer {
        position: relative;

        &:after {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: block;
            content: "";
            background: rgba($medium-blue, 0.1);
            transition: background-color 0.2s ease-in-out;
        }
        &:hover {
            cursor: pointer;
            &:after {
                background: rgba($medium-blue, 0);
            }
        }
    }
    .inactive-video-thumbnail {
        max-width: 213px;
        max-height: 175px;
    }
    .title:hover {
        cursor: pointer;
    }
}
.video-nav:not(:first-of-type) {
    margin-top: 15px;
    padding-top: 15px;
}
.file-list {
    list-style-type: none;
    padding: 0;

    li {
        list-style-type: none;
        padding: 0;
        font-size: 16px;
        border-radius: 0;
        border: 0;
        margin-bottom: 5px;

        a {
            color: $medium-blue;
            &:hover {
                color: $light-blue;
            }
        }
    }
}

.input-wrapper .emotion input:disabled,
.input-wrapper .emotion textarea:disabled {
    width: calc(100%);
}
.input-wrapper .emotion {
    margin-bottom: 10px;
}
.emotion-name {
    width: 200px;
}
.emotion-active {
    display: flex;
    align-items: center;

    .emotion-icon {
        margin-left: 30px;
    }
}

.emotion-icon__relative-wrapper {
    height: 40px;
    position: relative;
    width: 40px;

    > img {
        position: absolute;
        right: 0;
        top: 0;
    }
}

.left-side {
    *[data-react-beautiful-dnd-droppable] {
        & > * {
            z-index: 2;
            position: relative;

            &.slot {
                position: relative;
                z-index: 1;
            }
        }
    }
}
.dashboard-games-checkbox {
    margin-left: 20px;
}

.mr-20 {
    margin-right: 20px;
}

.font-size-20 {
    font-size: 20px;
}

.dashboard-paragraph {
    font-size: 20px;
}

.hide {
    display: none;
}

.dashboard {
    text-align: center;

    h3 {
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        color: $blue;
        max-width: 800px;
        margin: auto;
    }
    p {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $blue;
    }
    .more {
        position: relative;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $light-blue;
        padding-right: 18px;

        &:after {
            position: absolute;
            width: 8px;
            height: 20px;
            top: 0;
            right: 3px;
            bottom: 0;
            display: block;
            content: "";
            background: url(/images/more.svg) center center no-repeat;
            background-size: contain;
            transition: right 0.2s ease-in-out;
        }

        &:hover {
            text-decoration: underline;

            &:after {
                right: 0;
            }
        }
    }
    .app-link {
        display: block;
        padding: 30px;
        background: $light-gray;
        border: 1px solid $title-border;
        box-sizing: border-box;
        border-radius: 5px;
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        color: $dark-blue;
        box-shadow: 0 0 0 rgba(3, 26, 54, 0);
        transition: all 0.3s ease-in-out;

        margin: 90px 15px;

        &:hover {
            background: #ffffff;
            border: 1px solid $border-color;
            box-sizing: border-box;
            box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
            border-radius: 5px;
        }

        img {
            margin: 25px 0 0 0;
        }
    }
}
.card {
    border: none;
    padding: 30px 0;
    background: $white;
    box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
    border-radius: 5px;
    .input-wrapper {
        .input-content {
            @media (min-width: 1310px) {
                display: flex;
                flex-direction: row-reverse;
            }
        }
        @media (max-width: 1310px) {
            .input-label {
                width: 100% !important;
            }
        }
    }
}

.restOfTheFormWarning {
    background-color: $white;
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba($dark-blue, 0.15);
    margin-bottom: 48px;
    margin-top: 60px;
    padding: 20px;
    text-align: center;

    p {
        color: $blue;
        font-weight: 700;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
.audio-wrapper {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    background-color: #F5F5F5;
}

.download-button {
    display: flex;
    justify-content: center;
}

.client-profile-container {
    padding: 20px 10px 20px 10px;
}
ul {
    &.client-list {
        list-style: none;
        padding-inline-start: 30px;
        li {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: $dark-blue;

            &:before {
                content: "";
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                background-color: $light-blue;
                margin-right: 25px;
            }
        }
    }
}
.button-center {
    margin: 0 auto;
    min-width: 260px !important;
}
.nav-icon {
    position: relative;
    display: inline-block;
}
.label {
    &.label-notification {
        position: absolute;
        top: -5px;
        right: 15px;
        width: 8px;
        height: 8px;
        padding: 0;
        border-radius: 5px;
        background-color: $error;
    }
}
.toggler {
    .profile + .label {
        right: unset;
        left: 37px;
        top: 9px;
        width: 10px;
        height: 10px;
        border: 2px solid $medium-blue;
    }
}

.asset-form-preview {
    width: 140px;
    height: 140px;
    border-radius: 5px;
}
.dialog-warning {
    i.fa {
        font-size: 60px;
        color: $red;
        display: flex;
        justify-content: center;
        margin: 30px 0;
    }
    .title {
        display: block;
        line-height: 20px;
        font-size: 16px;
    }
    .description {
        display: block;
        font-size: 16px;
        line-height: 20px;
        margin-top: 23px;
    }
}
.tags {
    .tag {
        display: inline-block;
        padding: 5px 11px;
        background: $yellow;
        color: $black;
        border-radius: 5px;
        margin-right: 10px;
        margin-top: 10px;
        margin-bottom: 0;
        .title {
            padding: 0;
            margin: 0;
        }
        .btn {
            background-color: transparent;
            padding: 0;
            margin-left: 9px;
        }
    }
}

.tag-combo-input-group {
    max-width: 100%;
    width: 420px;
    position: absolute;
    top: 40px;
    background-color: $white;
    z-index: 999;

    .tag-combo-list-container {
        box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
        border: 1px solid $gray-blue;
        border-radius: 0px 0px 5px 5px;
        padding-top: 10px;
        .tag-combo-input-container {
            display: flex;
            flex-wrap: wrap;
            min-height: 45px;
            width: 100%;
            border-bottom: 1px solid $gray-blue;
            align-items: center;
            padding: 10px 10px 0 10px;
            .tag-combo-input {
                display: flex;
                margin-left: 5px;
                border: 0;
                background: transparent;
                width: auto;
                height: 45px;
                &:focus {
                    outline: 0;
                }
            }
            .tag-combo {
                display: flex;
                padding: 5px 11px;
            }
        }
        .tag-combo-list {
            padding: 0;
            max-height: 255px;
            overflow: auto;
            li {
                display: block;
                width: 100%;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                padding: 5px 11px 5px 11px;
                color: $medium-blue;
                &:hover {
                    cursor: pointer;
                    background: $light-gray;
                }

                &.active {
                    color: $light-blue;
                }
            }
        }
    }
    &.open {
        position: absolute;
        z-index: 10000;
        background: $white;
    }
}
.tag-combo {
    display: inline-block;
    height: 25px;
    line-height: 17px;
    padding: 3px 11px;
    background: $yellow;
    color: $dark-blue;
    border-radius: 5px;
    margin-right: 10px;

    &.inline {
        margin-bottom: 10px;
    }

    .tag-combo-title {
        padding: 0;
        margin: 0;
        font-weight: bold;
        font-size: 12px;
    }
    .btn {
        background-color: transparent;
        padding: 0;
        margin-left: 9px;
        font-size: 12px;
        line-height: 15px;
    }
}
.tag-header {
    padding-left: 11px;
    padding-right: 11px;
    margin-bottom: 5px;
    color: $light-blue;
    font-weight: bold;
    font-size: 11px;
    line-height: 14px;
    text-transform: uppercase;
}

.memory-game {
    .uploaded-image {
        .asset-name {
            display: none;
        }
    }
}

.verify-delete-faq-dialog-icon,
.verify-delete-sub-module-dialog-icon,
.verify-toggle-content-dialog-icon,
.verify-delete-everyday-situation-dialog-icon,
.verify-delete-everyday-situation-directory-dialog-icon,
.verify-delete-client-instant-award-dialog-icon,
.verify-delete-client-award-dialog-icon,
.error-sync-dialog-icon {
    color: $error;
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 70px;
}

.asset-form-preview {
    width: 140px;
    height: 140px;
    border-radius: 5px;
}
.memory-game {
    .uploaded-image {
        .asset-name {
            display: none;
        }
    }
}

.show-asset-dialog-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.show-asset-dialog-image {
    max-width: 100%;
    max-height: calc(100vh - 250px);
}

.content-overlay-icon,
.video-overlay-icon {
    opacity: 0;
    transition: 0.3s ease;
}

.awardsbase-image-container:hover,
.image-container:hover,
.table-image-container:hover,
.uploaded-image-container:hover,
.thumbnail-container:hover,
.video-image-container:hover {
    &.no-hover {
        .content-overlay {
            opacity: 0;
        }
        pointer-events: none;
    }

    cursor: pointer;
    .content-overlay,
    .video-content-overlay {
        opacity: 0.4;
    }
    .content-overlay-icon,
    .video-overlay-icon {
        opacity: 1;
    }
    .content-overlay-icon:hover {
        background: none;
    }
    .fa:hover {
        background: none;
    }
}

.content-overlay-icon,
.video-overlay-icon {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}
.video-overlay-icon {
    font-size: 50px;
}
.awardinput-instant-award-row {
    .checkbox-toggle {
        margin-top: 25px !important;
    }
}
.awardinput-instant-award-select-all-checkbox {
    color: $blue;
}
.tag-table-row {
    color: $dark-blue;

    .tag-actions {
        .btn {
            color: $blue;

            &:hover {
                color: $light-blue;
            }
        }
    }

    &:hover {
        &.table-row {
            border-top-color: transparent;
        }

        &::before {
            background-color: $white;
            border-radius: 5px;
            border: 1px solid $gray-blue;
            bottom: -1px;
            box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
            content: "";
            left: 0;
            pointer-events: none;
            position: absolute;
            right: 0;
            top: -1px;
            z-index: 1;
        }

        & > .table-cell {
            position: relative;
            z-index: 1;
        }

        & + .tag-table-row {
            border-top-color: transparent;
        }

        .tag-actions {
            opacity: 1;
        }
    }

    .tag-actions {
        transition: all 100ms ease-in-out;
        opacity: 0;
    }
}
.side-bar-left {
    .main-link {
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        text-transform: uppercase;

        &:before {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            content: "\f07b";
            font-size: 18px;
            padding-right: 5px;
            z-index: 1;
            position: relative;
            color: $dark-blue;
        }
    }
}
.small-text {
    font-size: 12px;
    color: $blue;
    font-weight: 700;
    text-transform: uppercase;
}
.knowledge-base-download-button {
    margin-top: 17px;
}
.knowledge-base-download-link-title {
    justify-content: flex-start !important;
}
.video-list-title {
    font-weight: bold;
    font-size: 18px;
    color: $blue;
}
.tab-panel-label {
    line-height: 14px;
    text-align: center;
}
.inactive-video-download-link {
    margin-top: 13px;
}
.award-table {
    .isDisabled {
        display: flex;
        justify-content: flex-end;
    }
}
.chart-title {
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    color: $blue;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 46px;
}
.chart-x-axis-label {
    margin-bottom: 11px;
}
.chart-y-axis-label-container {
    display: flex;
    justify-content: flex-end;
    width: 1200px;
}
.client-event-log-value-converter-table {
    td {
        border: none;
    }
    .client-event-log-value-converter-table-data {
        font-weight: bold;
        font-size: 16px;
        color: $medium-blue;
    }
}
.client-event-log-value-converter-table-show-more-container {
    cursor: pointer;
    display: flex;
    align-items: center;
}
.client-event-log-value-converter-table-show-more-text {
    text-decoration-line: underline;
    font-weight: bold;
    color: $blue;
    margin-right: 6px;
}
.loading-label {
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 27px;
    color: $blue;
    padding: 10px 0;
}
[id^=option-app-event-log-category] {
    padding-left: 47px !important;
    color: $medium-blue !important;
}
[id^=option-app-event-log-name] {
    padding-left: 76px !important;
}
.recharts-tooltip {
    background-color: rgba($color: $white, $alpha: 0.8);
    padding: 5px;
}
