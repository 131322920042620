@import "../../styles/inspinia/variables";

.slot {
    // background: #ffffff;
    border: 2px dashed $blue;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px;
    margin-top: 20px;

    font-weight: 500;
    font-size: 18px;
    line-height: 22px;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    &.image-slot {
        width: 140px;
        height: 140px;

        display: block;
        position: relative;
        padding: 15px;
        margin: 0;

        &:hover {
            cursor: pointer;
        }

        .upload-circle,
        .upload-text {
            width: 100%;
            margin: 0;
        }

        .upload-circle {
            top: 0;
        }
    }

    .upload-circle,
    .upload-text {
        display: inline-block;
        vertical-align: middle;
        margin: 0 10px;
    }

    .upload-circle {
        position: relative;
        top: 10px;
        width: 51px;
        height: 66px;

        background: url(/images/upload.svg) center center no-repeat;
        background-size: contain;
    }

    &.error {
        border: 2px dashed $error;
        color: $error;

        .upload-circle {
            background: url(/images/upload-red.svg) center center no-repeat;
        }
    }

    &.file-upload {
        font-size: 14px;
        line-height: 16px;
        background: #F5F5F5;
        border: 2px dashed #D8E4E8;
        box-sizing: border-box;
        border-radius: 10px;
        padding-top: 27px;

        &.image-slot {
            width: 145px;
            height: 145px;
        }

        .upload-circle {
            top: 0;
            width: 55px;
            height: 75px;
        }

        .upload-text {
            margin-top: 17px;
        }
    }
    &.disabled {
        border-color: $gray-blue;
        display: flex;
    }
}

.uploaded-image {
    position: relative;
    display: flex;
    align-items: center;

    .uploaded-image-container {
        position: relative;
        width: 145px !important;
        height: 145px !important;
        border-radius: 5px;
        overflow: hidden;

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            display: block;
        }
    }

    .btn.large-icon {
        position: absolute;
        top: -6px;
        left: 126px;
        z-index: 2;
        width: 25px;
        height: 25px;
        text-align: center;
        padding: 0;
        line-height: 4px;
        background: $error;
        border: 2px solid #fff;
        border-radius: 50%;
        color: #fff;
        transform: rotate(0);
        transition: transform 0.3s ease-in-out;

        span {
            margin: 0;
            font-size: 12px;
            line-height: 10px;
        }

        &:hover {
            background: $error;
            transform: rotate(90deg);
            color: #fff;

            span {
                color: #fff;
            }
        }
    }

    .asset-name {
        padding-left: 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $medium-blue;

        &:empty {
            display: none;
        }
    }

    &.file-upload {
        .uploaded-image-container {
            width: 110px;
            height: 110px;
        }
        .btn.large-icon {
            left: 96px;
        }
        .uploaded-image-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            transition: 0.5s ease;
            background-color: $black;
        }

        .uploaded-image-container:hover .uploaded-image-overlay {
            opacity: 0.6;
            cursor: pointer;
        }

        .uploaded-image-view-button {
            color: white;
            font-size: 40px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
        }
    }
}
