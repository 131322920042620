@import "../../styles/inspinia/variables";
@import "../../styles/inspinia/mixins";

.action-button-container {
    text-align: right;
    margin-bottom: 20px;

    button {
        & + button {
            margin-left: 20px;
        }
    }
}

form {
    position: relative;

    .edit-button,
    .pin-buttons {
        position: absolute;
        top: 12px;
        bottom: auto;
        right: 0;
        margin: auto;
        height: 30px;
    }
    .pin-buttons {
        button {
            padding: 3px 6px 8px 6px;
        }
    }
}
.grid-padding-x:not(.fixed-row) {
    margin-left: -15px;
    margin-right: -15px;
}
.asset-object-form {
    margin-top: 25px;
    padding-top: 25px;

    .label {
        background-color: transparent;
        padding: 3px 15px;
        color: $blue;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
    }

    &:first-of-type {
        border: none;
        margin-top: 0;
        padding-top: 0;
    }
}

.asset-object-tags {
    .tags {
        margin-left: -5px;
        margin-right: -5px;
        margin-top: -15px;

        .tag {
            margin-right: 5px;
            margin-left: 5px;
            padding: 5px 10px;
            display: inline-flex !important;
        }
    }
}

.main-content .label {
    background: none;
    font-size: 16px;
    line-height: 24px;
    padding-top: 0;
    color: $blue;
    display: inline-block;
    vertical-align: middle;
    font-weight: 700;
    align-items: center;
    display: flex;
    height: 50px;
    padding-left: 15px;
    box-sizing: border-box;
}
.input-wrapper {
    margin-bottom: 25px;

    .input-label {
        font-size: 12px;
        line-height: 24px;
        padding-top: 0;
        width: 200px;
        color: $blue;
        display: inline-block;
        vertical-align: middle;
        font-weight: 700;
        align-items: center;
        display: flex;
        height: 50px;
        padding-right: 15px;
        box-sizing: border-box;
        text-transform: uppercase;

        @include breakpoint(large) {
            width: 250px;
        }

        &.original-case {
            text-transform: inherit;
        }

        &.padded {
            position: relative;
            padding-left: 50px;

            .fa,
            .icon {
                position: absolute;
                color: $medium-blue;
                left: 5px;
                top: 1px;
                bottom: 0;
                margin: auto;
                font-size: 22px;
                height: 22px;
            }
        }
    }

    &.sub-level {
        .input-label {
            font-size: 16px;
            font-weight: 500;
            color: $dark-blue;
        }
    }

    .radio-group {
        padding-top: 10px;
    }

    .input-content {
        width: calc(100% - 200px);
        display: inline-block;
        vertical-align: middle;
        min-height: 32px;

        @include breakpoint(large) {
            width: calc(100% - 250px);
        }
    }

    input,
    textarea {
        -webkit-appearance: none;
        background-color: rgb(252, 252, 250);
        caret-color: $medium-blue;
        color: $medium-blue;
        border-radius: 5px;
        border: 2px solid $light-gray-2;

        display: block;
        font-size: 16px;
        height: 50px;
        line-height: 1;
        padding: 12px 15px;
        width: 100%;

        &:disabled {
            background-color: #fff;
            border-color: transparent;
            color: $medium-blue;
            font-weight: 700;
            width: calc(100% - 50px);

            & + .button-wrapper {
                right: 60px;
            }
        }

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $place-holder;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $place-holder;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $place-holder;
        }

        &:-webkit-autofill {
            //-webkit-box-shadow: inset 0 0 0 #fff;
            -webkit-text-fill-color: #000;
        }

        &:valid {
            border: 2px solid $blue;
        }
        &:placeholder-shown {
            border: 2px solid $light-gray-2;
        }
        &:focus {
            border: 2px solid $light-blue;
        }
        &:read-only {
            border-color: transparent;
            cursor: not-allowed;
            outline-color: transparent;
        }

        &.has-error {
            border: 2px solid $error;
        }
    }
    textarea {
        height: 150px;
        line-height: 1.5;
    }

    .hidden {
        display: none;
    }

    &.input-wrapper--align-center {
        justify-content: center;

        .cell {
            &.shrink {
                max-width: 100%;
            }
        }
    }
}

.checkbox-wrapper {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;
    padding-left: 50px;
    padding-top: 10px;

    .checkbox-toggle {
        background-color: #fff;
        border: 2px solid $blue;
        box-sizing: border-box;
        border-radius: 2px;
        cursor: pointer;
        display: block;
        height: 30px;
        left: 0;
        position: absolute;
        top: 0;
        width: 30px;
        transition: background-color 0.2s ease-in-out;
        margin-top: 10px;

        margin-top: 10px;

        &.has-error {
            background-color: $light-gray;
            border: 2px solid $error;
        }
    }

    .checkbox-label {
        padding-top: 5px;
        line-height: 1.4;
    }

    .icon-wrapper,
    .icon-indeterminate-wrapper {
        display: none;
        height: 0;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 100%;
        position: relative;
        width: 19px;

        svg {
            display: block;
            height: 100%;
            position: absolute;
            width: 100%;
            color: $light-blue;
        }
    }

    .show-for-sr {
        height: 30px !important;
        width: 30px !important;
        padding: 0 !important;
        top: 10px;
        left: 0;
    }

    input[type="checkbox"] {
        &:checked {
            & ~ .checkbox-toggle {
                border-color: $blue;
                background-color: $blue;

                .icon-wrapper {
                    display: block;
                }
            }
        }
        &:indeterminate {
            & ~ .checkbox-toggle {
                border-color: $blue;

                .icon-indeterminate-wrapper {
                    display: block;
                }
            }
        }
    }
    input[type="checkbox"] {
        &:disabled {
            & ~ .checkbox-toggle {
                cursor: default;
                border-color: rgba($place-holder, 0.7);
                background-color: rgba($place-holder, 0.2);
            }
            &:checked {
                & ~ .checkbox-toggle {
                    cursor: default;
                    border-color: rgba($place-holder, 0.7);
                    background-color: rgba($place-holder, 0.7);
                }
            }
        }
    }
}

.radio-wrapper {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 20px;
    padding-left: 50px;

    .radio-toggle {
        background: #fff;
        border: 2px solid $blue;
        box-sizing: border-box;
        border-radius: 2px;
        cursor: pointer;
        display: block;
        height: 30px;
        left: 0;
        position: absolute;
        top: 0;
        width: 30px;
        border-radius: 50%;
        transition: all 0.2s ease-in-out;

        &:after {
            position: absolute;
            display: block;
            content: "";
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            background: #fff;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
    }

    input[type="radio"] {
        &:checked {
            & ~ .radio-toggle {
                border-color: $blue;
                background-color: $blue;
                &:after {
                    opacity: 1;
                }
            }
        }
    }
}
.input-info {
    color: #0d325f;
    display: block;
    font-size: 16px;
    line-height: 1.2;
    padding: 12px 15px;
    font-weight: 700;

    span {
        display: inline-block;

        + span {
            margin-left: 10px;
        }
    }
}
