@import "../../../styles/inspinia/variables";

.file-input-wrapper{
  display: inline-block;

  &, * {
    &, &:focus {
      outline: 0;
    }
  }
}

.profile-image-container {
  position: relative;
  width: 188px;
  height: 188px;
  margin: 30px auto;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    display: block;
  }
  button {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
    width: 25px;
    height: 25px;
    text-align: center;
    padding: 0;
    line-height: 4px;
    background: $error;
    border: 2px solid #fff;
    border-radius: 50%;
    color: #fff;
    transform: rotate(0);
    transition: transform .3s ease-in-out;
    
    span {
      margin: 0;
      font-size: 12px; 
      line-height: 10px;
    }

    &:hover {
      transform: rotate(90deg);
      color: #fff;
      
      span {
        color: #fff;
      }
    }
  }
  .upload-holder {
    &:hover {
      cursor: pointer;
    }
  }
  svg {
    position: absolute;
    width: 46px;
    height: 46px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
    pointer-events: none;
  }
}