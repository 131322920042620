.loading {
    .loading-animation {
        position: relative;
        margin: 2em auto;
        height: 60px;
        width: 60px;
        text-align: center;
        display: block;

        svg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transform: rotate(0);

            animation: rotate 1s infinite ease-in-out;
        }
    }
    &.input {
        .loading-animation {
            position: absolute;
            top: 0;
            right: 10px;
            bottom: 0;
            margin: auto;
            height: 30px;
            width: 30px;
            text-align: center;
            display: block;

            svg {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                transform: rotate(0);

                animation: rotate 1s infinite ease-in-out;
            }
        }
    }
    &.layered {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(#fff, 0.6);
        z-index: 30001;

        .loading-animation {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 80px;
            width: 80px;
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}
