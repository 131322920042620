@import "../../styles/inspinia/variables";

.inside-tabs {
    .react-tabs {
        .react-tabs__tab-list {
            justify-content: left;
            background: $light-gray;
            padding-left: 10px;

            .react-tabs__tab {
                background: $gray-blue;
                position: relative;
                margin-left: 10px;
                transition: all 0.2s ease-in-out;

                & > a {
                    font-size: 16px;
                    text-transform: none;
                    color: $blue;
                }
                &.is-selected {
                    background: #fff;

                    &::before {
                        background-color: $light-blue;
                        bottom: 100%;
                        content: "";
                        height: 3px;
                        left: 0;
                        position: absolute;
                        right: 0;
                    }
                }
                &:not(.is-selected):hover {
                    background: rgba($gray-blue, 0.5);
                }
                &:hover,
                &.is-selected {
                    &::after {
                        display: none;
                    }
                }
            }
        }
        .react-tabs__tab-panel {
            background: #fff;
            & > div {
                padding: 20px;
            }
        }
    }
}
