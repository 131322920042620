@import "../../styles/inspinia/variables";

.education-sub-module-list-page {
    .action-buttons {
        display: flex;
        visibility: hidden;
        justify-content: flex-end;
        padding-left: 5px;
        padding-right: 5px;

        button, .move-icon {
            padding-left: 5px;
            padding-right: 5px;
        }

        .move-icon {
            display: flex;
            flex-direction: column;
            color: $medium-blue;
            opacity: 0.5;
            font-size: 20px;
        }
    }
    .table-row {
        &:hover {
            background: $white;
            border: 1px solid $gray-blue;
            box-sizing: border-box;
            box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
            border-radius: 5px;
            z-index: 1;
            .action-buttons {
                visibility: visible;
            }
        }
    }
}