/* html */
html {
    *::-webkit-scrollbar:horizontal {
        height: 10px !important;
    }

    *::-webkit-scrollbar-thumb:horizontal {
        border-top: 0 !important;
        border-bottom: 0 !important;
    }
}

/* AgendaItemInput */
.agenda-group-selector {
    .draggable-content-element {
        .content-element {
            background-color: transparent;
            border: 0;
            margin: 0;
            padding: 0;
            transition: none;
        }

        .content-element-image {
            .image-container {
                height: 110px;
                width: 110px;
            }
        }

        .content-element-content {
            .content-element-title {
                h3 {
                    border: 0;
                    color: $blue;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 28px;
                    margin: 9px 0 38px;
                    padding: 0;
                }
            }
        }

        .content-element-subgroup {
            border-bottom: 1px solid $gray-blue;
            cursor: pointer;
            height: auto;
            margin-bottom: 15px;
            padding-bottom: 15px;

            .subgroup-image {
                flex-basis: auto;
                height: 50px;
                width: 75px;

                .content-element-image {
                    height: 50px;
                    width: 50px;
                }
            }

            .subgroup-title {
                h3 {
                    border: 0;
                    color: $medium-blue;
                    font-size: 18px;
                    font-weight: 700;
                    line-height: 22px;
                    margin: 0 15px 0 0;
                    padding: 0;
                }
            }

            &:hover {
                .subgroup-title {
                    h3,
                    .fa {
                        color: $light-blue;
                    }
                }
            }
        }

        .subelements-group {
            padding-left: 15px;

            .content-subelement {
                .subelement-image {
                    flex-basis: auto;
                    height: 50px;
                    width: 75px;

                    .content-element-image {
                        height: 50px;
                        width: 50px;
                    }
                }

                .subelement-title {
                    h3 {
                        margin-bottom: 9px;
                    }
                }
            }
        }

        & + .draggable-content-element {
            margin-top: 12px;
        }

        &:focus,
        &:hover {
            background: transparent;
            box-shadow: none;
            cursor: inherit;
        }
    }

    &:active,
    &:hover {
        .draggable-content-element {
            .content-element-subgroup {
                border-bottom-color: $border-color;
            }
        }
    }
}

/* FlowchartItemInput */
.select-flowchart {
    .draggable-content-element {
        .content-element {
            margin: 20px 0;
        }

        .content-element-image {
            .image-container {
                height: 110px;
                width: 110px;
            }
        }
    }

    .slot {
        margin-top: 20px;
        min-height: 228px;
    }
}

.select-flowchart__top-section {
    .draggable-content-element {
        .content-element {
            background-color: transparent;
            border: 0;
            margin: 20px 0 0;
            padding: 0;
            transition: none;
        }

        .content-element-content {
            .content-element-title {
                h3 {
                    margin-bottom: 0;
                }

                .buttons {
                    display: none;
                }
            }
        }

        .drag-space {
            display: none;
        }

        .content-element-icons {
            margin-bottom: 20px;
            margin-top: 25px;
        }

        &:focus,
        &:hover {
            background: transparent;
            box-shadow: none;
            cursor: inherit;
        }
    }
}

.select-flowchart__bottom-section {
    .draggable-content-element {
        .content-element {
            min-height: 228px;

            .content-element-flex {
                align-items: flex-end;
            }
        }

        .content-element-content {
            .content-element-title {
                position: static;

                h3 {
                    font-size: 22px;
                    line-height: 27px;
                    margin-bottom: 0;
                }

                .buttons {
                    bottom: auto;
                    right: 10px;
                    top: 20px;
                }
            }
        }

        .content-element-icons {
            left: 10px;
            position: absolute;
            top: (10 + 110 + 30 - 5) *1px;
            z-index: 1;
        }
    }
}

/* EducationSubModuleForm */
.education-sub-module-form {
    padding: 25px 30px !important;

    .table {
        margin-bottom: 0 !important;
    }

    .videos-section {
        .table-cell {
            padding: 10px !important;

            &.title {
                margin-right: 70 - 20px !important;
            }

            &.inputs {
                align-self: flex-start !important;
                flex: 1 1 0px !important;
                padding-top: 20px !important;
                width: auto !important;

                > span {
                    width: 100%;
                }
            }
        }

        .input-wrapper {
            .input-label {
                flex: 1 1 0px !important;
                width: auto !important;
            }

            .input-content {
                padding-right: 10px !important;
                width: 400px !important;
            }
        }
    }

    .education-sub-module-form-video-action-buttons {
        bottom: 0px;
        position: absolute;
        right: 20px;
    }
}

/* Button */
.btn--text-large {
    font-size: 16px !important;
    line-height: 20px !important;
}

/* Calendar */
.calendar {
    .calendar-body {
        .cell {
            &:hover {
                .menu {
                    z-index: 4;
                }
            }
        }
    }
}

/* ColorSelect */
.color-option {
    &.color-option--has-border {
        border: 2px solid $title-border;
    }
}

/* DateInput */
// TODO: change later original rect-datepicker styles (in _custom.scss, nested under .subpage) to these. For now, these are Dialog only.
.modal-layer {
    .react-datepicker {
        border-radius: 5px;
        border: 0;
        box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
        font-family: "Quicksand", sans-serif;
        font-size: 14px;
        font-weight: 500;
    }

    .react-datepicker__navigation {
        background-position: center;
        background-repeat: no-repeat;
        border: 0;
        height: 3 * 9px;
        width: 25px;
    }

    .react-datepicker__navigation--previous {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMSIgZmlsbD0ibm9uZSIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNyAxMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KIDxwYXRoIGQ9Ik02IDFMMSA1LjIzNjY5TDYgMTAiIHN0cm9rZT0iI2ZmZiIvPgo8L3N2Zz4K");
        left: 0;
    }

    .react-datepicker__navigation--next {
        background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSIxMSIgZmlsbD0ibm9uZSIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgNyAxMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KIDxwYXRoIGQ9Im0xIDEwIDUtNC4yMzY3LTUtNC43NjMzIiBzdHJva2U9IiNmZmYiLz4KPC9zdmc+Cg==");
        right: 0;
    }

    .react-datepicker__header {
        background-color: $blue;
        border-bottom: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding-top: 12px;
    }

    .react-datepicker__month {
        margin: 7px 2px;
    }

    .react-datepicker-wrapper {
        display: block;
    }

    .react-datepicker__input-container {
        position: relative;

        &:hover {
            cursor: pointer;
        }

        &::after {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            position: absolute;
            display: block;
            content: "\f073";
            line-height: 40px;
            font-size: 26px;
            top: 4px;
            bottom: 4px;
            right: 4px;
            width: 30px;
            color: $light-blue;
            pointer-events: none;
        }
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
        color: $white;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        margin-bottom: 10px;
    }

    .react-datepicker__year-dropdown-container--select,
    .react-datepicker__month-dropdown-container--select,
    .react-datepicker__month-year-dropdown-container--select,
    .react-datepicker__year-dropdown-container--scroll,
    .react-datepicker__month-dropdown-container--scroll,
    .react-datepicker__month-year-dropdown-container--scroll {
        margin: 0 8px;
        position: relative;

        &::after {
            background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgZmlsbD0ibm9uZSIgdmVyc2lvbj0iMS4xIiB2aWV3Qm94PSIwIDAgMTEgNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KIDxwYXRoIGQ9Ik0xIDFMNS4yMzY2OSA2TDEwIDEiIHN0cm9rZT0iI2ZmZiIvPgo8L3N2Zz4K");
            background-position: center;
            background-repeat: no-repeat;
            bottom: 0;
            content: "";
            pointer-events: none;
            position: absolute;
            right: 0;
            top: 0;
            width: 3 * 9px;
            z-index: 1;
        }
    }

    .react-datepicker__month-select,
    .react-datepicker__year-select {
        appearance: none;
        background-color: $blue;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: $white;
        display: block;
        font-weight: 500;
        height: 22px;
        line-height: 18px;
        max-width: 100%;
        padding: 1px 3 * 9px 1px 6px;
    }

    .react-datepicker__day-name {
        color: $white;
        font-size: 12px;
        line-height: 15px;
        margin: 12px 2px 7px;
        width: 30px;
    }

    .react-datepicker__day {
        border-radius: 15px;
        color: $dark-blue;
        height: 30px;
        line-height: 30px;
        margin: 2px;
        width: 30px;

        &:hover {
            background-color: $gray-blue;
            border-radius: 15px;
        }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected {
        background-color: $blue;
        color: $white;

        &:hover {
            background-color: $light-blue;
        }
    }

    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled {
        color: $light-blue;

        &:hover {
            color: $white;
        }
    }

    .react-datepicker-popper[data-placement^="top"] {
        .react-datepicker__triangle {
            &::before {
                border-top-color: transparent;
            }
        }
    }

    .react-datepicker-popper[data-placement^="bottom"] {
        .react-datepicker__triangle {
            border-bottom-color: $blue;

            &::before {
                border-bottom-color: transparent;
            }
        }
    }
}

/* Overwrite icons not present in Font Awesome Free */
// DateInput
.subpage .cell:not(.doEdit) .react-datepicker__input-container,
.modal-layer .react-datepicker__input-container {
    &::after {
        content: "\f073";
        color: $blue;
    }
}

.fa {
    > svg {
        display: inline;
    }

    &.fa-book {
        &::before {
            content: normal;
        }
    }
}

/* Table */
.table {
    .table-row,
    .table-header {
        .table-cell,
        .header-cell {
            &.actions {
                flex-basis: auto !important;
                width: 100px !important;
            }
        }
    }

    .table-row {
        .actions,
        .tag-actions {
            opacity: 0;
            transition: opacity;

            .btn {
                color: $blue;

                &:hover {
                    color: $light-blue;
                }
            }
        }

        &:not(.no-hover):hover {
            &.table-row {
                border-top-color: transparent;
            }

            &::before {
                background-color: $white;
                border-radius: 5px;
                border: 1px solid $gray-blue;
                bottom: -1px;
                box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
                content: "";
                left: 0;
                pointer-events: none;
                position: absolute;
                right: 0;
                top: -1px;
                z-index: 1;
            }

            & > .table-cell {
                position: relative;
                z-index: 1;
            }

            & + .tag-table-row {
                border-top-color: transparent;
            }

            .actions,
            .tag-actions {
                opacity: 1;
            }
        }
    }
}

.table--display-table {
    .table {
        display: table !important;

        .empty {
            height: 200px;

            > * {
                left: 0;
                position: absolute;
                right: 0;
                top: 50%;
            }
        }
    }

    // tr
    .table-header,
    .table-row {
        display: table-row !important;
    }

    .table-header {
        > .header-cell {
            border-top: 1px solid $gray-blue;
            padding: 30px 0 0 !important;
            vertical-align: bottom !important;
            width: auto !important;

            .title {
                font-weight: 700 !important;
            }

            & + .header-cell {
                padding-left: 40px !important;
            }
        }
    }

    .table-row {
        > .table-cell {
            border-bottom: 1px solid $gray-blue;
            padding: 20px 0 25px !important;
            vertical-align: top !important;
            width: auto !important;

            & + .table-cell {
                padding-left: 40px !important;
            }
        }

        &:hover {
            &::before {
                content: normal !important;
            }
        }
    }

    // tbody
    .table-body {
        display: table-row-group !important;
        font-size: 16px !important;
        line-height: 20px !important;
    }

    .table-footer {
        height: 78px !important;
        margin: 0 !important;

        > .btn-group {
            &.pager {
                bottom: 20px !important;
                justify-content: center !important;
                left: 0 !important;
                position: absolute !important;
                right: 0 !important;
            }
        }
    }

    // th
    .header-cell {
        display: table-cell !important;

        &.title {
            min-width: 240px !important;
        }

        &.type {
            width: 240px !important;
        }
    }

    // td
    .table-cell {
        display: table-cell !important;

        &.createdAt,
        &.startedAt,
        &.finishedAt {
            white-space: nowrap;
        }

        &.data {
            padding-bottom: 20 - 12px !important;
            padding-top: 20 - 12px !important;
        }
    }

    .client-event-log-value-converter-table td {
        padding-left: 0 !important;
    }

    .client-event-log-value-converter-table-show-more-text {
        white-space: nowrap !important;
    }
}

/* Tabs */
.tab-panel-label {
    color: $medium-blue;
    opacity: 0.7;
}

.inside-tabs {
    .react-tabs {
        background-color: transparent !important;

        .react-tabs__tab-list {
            position: relative;

            &::before {
                background-color: $gray-blue;
                bottom: 0;
                content: "";
                height: 1px;
                left: 0;
                position: absolute;
                right: 0;
                z-index: 1;
            }

            .react-tabs__tab {
                z-index: 1;

                &::before {
                    // TODO: #c0cedb?
                    background-image: linear-gradient(180deg, $gray-blue 82.29%, #c0cedb 100%);
                    bottom: 0;
                    content: "";
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 1;
                }

                & > a {
                    position: relative;
                    z-index: 1;
                }

                &.is-selected {
                    &::before {
                        background-image: none;
                    }

                    .tab-panel-label {
                        color: $blue;
                        opacity: 1;
                    }
                }
            }
        }

        .react-tabs__tab-list--display-table {
            border-collapse: separate;
            border-spacing: 10px 0;
            display: table;
            padding: 0 !important;

            .react-tabs__tab {
                display: table-cell;
                margin: 0 !important;
                min-width: auto;
                vertical-align: middle;
            }
        }
    }
}

/* Utility classes */

// color
.clr-blue {
    color: $blue !important;
}

.clr-error {
    color: $error !important;
}

// font-size
.fs-12 {
    font-size: 12px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-25 {
    font-size: 25px !important;
}

// line-height

.lh-20 {
    line-height: 20px !important;
}

// font-weight
.fw-700 {
    font-weight: 700 !important;
}

// text-transform
.text-uppercase {
    text-transform: uppercase !important;
}

.text-transform-none {
    text-transform: none !important;
}

// margin-top
.mt-0 {
    margin-top: 0 !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

// margin-right
.mr-auto {
    margin-right: auto !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-20 {
    margin-right: 20px !important;
}

// margin-bottom
.mb-5 {
    margin-bottom: 5px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-25 {
    margin-bottom: 25px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-35 {
    margin-bottom: 35px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-60 {
    margin-bottom: 60px !important;
}

// margin-left
.ml-0 {
    margin-left: 0 !important;
}

.ml-auto {
    margin-left: auto !important;
}

// border-top
.bt-0 {
    border-top: 0 !important;
}

// border-right
.br-0 {
    border-right: 0 !important;
}

// border-bottom
.bb-0 {
    border-bottom: 0 !important;
}

// border-left
.bl-0 {
    border-left: 0 !important;
}

// padding-top
.pt-0 {
    padding-top: 0 !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-54 {
    padding-top: 54px !important;
}

// padding-right
.pr-0 {
    padding-right: 0 !important;
}

.pr-15 {
    padding-right: 15px !important;
}

.pr-25 {
    padding-right: 25px !important;
}

.pr-30 {
    padding-right: 30px !important;
}

.pr-40 {
    padding-right: 40px !important;
}

// padding-bottom
.pb-0 {
    padding-bottom: 0 !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-54 {
    padding-bottom: 54px !important;
}

// padding-left
.pl-0 {
    padding-left: 0 !important;
}

.pl-15 {
    padding-left: 15px !important;
}

.pl-25 {
    padding-left: 25px !important;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-40 {
    padding-left: 40px !important;
}

.pl-50 {
    padding-left: 50px !important;
}

.pl-135 {
    padding-left: 135px !important;
}

// width
.w-135 {
    width: 135px !important;
}

.w-140 {
    width: 140px !important;
}

.w-780 {
    width: 780px !important;
}

// max-width
.maxW-320 {
    max-width: 320px !important;
}

// height
.h-auto {
    height: auto !important;
}

.h-140 {
    height: 140px !important;
}

// right
// Note: "n": "negative"
.right-n6 {
    right: -6px !important;
}

// left
.left-auto {
    left: auto !important;
}

// recharts
.recharts-cartesian-grid-horizontal {
    line {
        &[y1="0"] {
            stroke: transparent;
        }
    }
}

.dark-blue {
    background-color: #003C71 !important;
}
