@import "../../styles/inspinia/mixins";
@import "../../styles/inspinia/variables";

.dashboard-content-share-request-table {
    .table-image-name {
        display: flex;
        align-items: center;
    }

    .table-image-container {
        flex-basis: 30px;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        margin-right: 15px;
        flex-grow: 0;
        flex-shrink: 0;

        @include breakpoint(xlarge) {
            width: 50px;
            height: 50px;
            flex-basis: 50px;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            object-fit: cover;
            object-position: center;
        }
    }

    .table-header-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
        .table-title {
            .table-title-text {
                font-weight: 500;
                font-size: 20px;
                color: $blue;
            }
            .table-title-count {
                margin-left: 10px;
                font-weight: bold;
                font-size: 14px;
                color: $blue;
            }
        }
        .table-link-to-all {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: $light-blue;
            cursor: pointer;
        }
    }
}