@import "../../../../styles/inspinia/variables";
@import "../../../../styles/inspinia/mixins";
@import "../../../..//styles/inspinia/variables";

.draggable-content-element {
    .grabbed {
        .content-element {
            background: $white;
            box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
            margin-top: 0;
        }
    }

    .content-element {
        position: relative;
        margin: 10px 0;
        padding: 10px;
        background: $light-gray;
        border: 1px solid $gray-blue;
        box-sizing: border-box;
        border-radius: 5px;
        transition: all 0.2s ease-in-out;

        .content-element-flex {
            display: flex;
        }

        .view,
        .edit,
        .delete {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
    }

    &:focus,
    &:hover {
        cursor: pointer;
        background: $white;
        box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
    }
    &:hover {
        .view,
        .edit,
        .delete {
            opacity: 1;
        }
    }

    .content-element-image {
        display: flex;
        position: relative;

        &:nth-of-type(2) {
            margin-left: 10px;
        }

        .image-container {
            position: relative;
            border-radius: 5px;
            width: 60px;
            height: 60px;
            overflow: hidden;
            align-self: center;

            @include breakpoint(xlarge) {
                width: 110px;
                height: 110px;
            }

            &:nth-of-type(2) {
                margin-left: 10px;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                display: block;
                border-radius: 5px;
            }
        }
    }

    .content-element-content {
        flex: 1;
        padding-left: 20px;

        .content-element-title {
            position: relative;

            h3 {
                font-weight: bold;
                font-size: 20px;
                line-height: 25px;
                color: $blue;
                margin: 10px 0 10px 0;
                padding: 0;

                .fa {
                    position: relative;
                    display: inline-block;
                    margin-left: 15px;
                    top: 2px;
                }

                @include breakpoint(xlarge) {
                    font-size: 22px;
                    line-height: 27px;
                    margin: 10px 0 20px 0;
                }
            }
            .buttons {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;

                .btn {
                    padding: 5px 10px;
                }
            }
        }
    }

    .drag-space {
        position: absolute;
        z-index: 2;
        width: 20px;
        height: 30px;
        right: 15px;
        bottom: 15px;

        .up {
            display: block;
            position: absolute;
            top: 2px;
            left: 0;
            right: 0;
            width: 0;
            height: 0;
            margin: auto;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 7px solid $dark-blue;
            opacity: 0.5;
            transition: opacity 0.2s ease-in-out;
        }
        .down {
            display: block;
            position: absolute;
            bottom: 2px;
            left: 0;
            right: 0;
            width: 0;
            height: 0;
            margin: auto;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 7px solid $dark-blue;
            opacity: 0.5;
            transition: opacity 0.2s ease-in-out;
        }

        .down,
        .up {
            &:hover {
                cursor: pointer;
                opacity: 1;
            }
        }
    }

    .content-element-icons {
        span {
            display: inline-block;
            vertical-align: middle;
        }

        .time {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            display: none;

            @include breakpoint(xlarge) {
                font-size: 16px;
            }

            &.active {
                opacity: 1;
                display: inline-block;
            }
        }

        .fa {
            position: relative;
            width: 25px;
            height: 25px;
            overflow: hidden;
            margin: 5px 8px 5px 0;
            text-indent: -300px;

            @include breakpoint(xlarge) {
                width: 30px;
                height: 30px;
                margin: 10px 20px 10px 0;
            }

            opacity: 0.2;

            &.active {
                opacity: 1;
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                text-align: center;
                margin: auto;
                font-size: 20px;
                line-height: 26px;
                text-indent: 0;

                @include breakpoint(xlarge) {
                    font-size: 24px;
                    line-height: 30px;
                }
            }
        }
    }

    .content-element-subgroup {
        display: flex;
        align-items: center;
        margin-top: 10px;
        height: 60px;

        .subgroup-image {
            position: relative;
            width: 70px;
            height: 60px;
            flex-basis: 70px;

            .content-element-image {
                position: relative;
                border-radius: 5px;
                width: 60px;
                height: 60px;
                overflow: hidden;
                align-self: center;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    display: block;
                    border-radius: 5px;
                }
            }
        }
        .subgroup-title {
            flex: 1;
            position: relative;

            h3 {
                font-weight: normal;
                font-size: 18px;
                line-height: 22px;
                color: $medium-blue;
                padding: 0 10px;
                display: inline-block;
            }
        }
    }

    .subelements-group {
        padding-left: 20px;

        .content-subelement {
            display: flex;
            align-items: center;
            margin: 15px 0;

            .subelement-image {
                position: relative;
                width: 70px;
                height: 60px;
                flex-basis: 70px;

                .content-element-image {
                    position: relative;
                    border-radius: 5px;
                    width: 60px;
                    height: 60px;
                    overflow: hidden;
                    align-self: center;

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        display: block;
                        border-radius: 5px;
                    }
                }
            }
            .subelement-title {
                flex: 1;
                position: relative;

                h3 {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 20px;
                    color: $medium-blue;
                    padding: 0;
                    margin: 0;
                    display: inline-block;
                }
            }
            .content-element-icons .fa {
                width: 20px;
                height: 20px;
                margin: 0 10px 0 0;

                &::before {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }

    .agenda-group {
        position: relative;
        margin: 10px 0;
        padding: 10px 10px 0 10px;
        background: $light-gray;
        border: 1px solid $gray-blue;
        box-sizing: border-box;
        border-radius: 5px;
        transition: all 0.2s ease-in-out;

        .agenda-title {
            position: relative;
            padding-bottom: 10px;

            h3 {
                font-weight: 500;
                font-size: 26px;
                line-height: 32px;
                color: $blue;
                margin: 0;
                padding: 0;
            }
            .buttons {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 132px;
            }
        }

        .view,
        .edit,
        .delete {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }

        &:focus,
        &:hover {
            cursor: pointer;
            background: #ffffff;
            box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
        }
        &:hover {
            .view,
            .edit,
            .delete {
                opacity: 1;
            }
        }

        .content-element {
            &,
            &:focus,
            &:hover {
                padding-left: 0;
                padding-right: 0;
                background: none;
                border: none;
                box-shadow: none;
                margin: 0;
                border-top: 1px solid $border-color;
            }
        }
    }
}

.agenda-group-selector__heading {
    border: 0;
    color: $blue;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    margin: 0;
    padding: 0;
}

.agenda-group-selector__head {
    border-bottom: 1px solid $gray-blue;
    margin-bottom: 15px;
    min-height: 68px;
    padding-bottom: 5px;
    padding-right: 130px;
    padding-top: 6px;
}

.agenda-group-selector__element-buttons-wrapper {
    opacity: 0;
    position: absolute;
    right: 5px;
    top: 10px;
    transition: opacity 150ms;
    z-index: 1;
}

.agenda-group-selector__drag-space {
    border-top: 1px solid $gray-blue;
    height: 53px;
    margin-top: 12px;
    padding-bottom: 8px;
    padding-top: 8px;

    &:empty {
        border: 0;
        height: 14px;
        margin: 0;
        padding: 0;
    }
}

.agenda-group-selector__drag-space-button {
    background-color: transparent;
    border: 0;
    color: $medium-blue;
    cursor: pointer;
    display: block;
    font-size: 18px;
    height: 18px;
    line-height: 18px;
    margin: 0 0 0 auto;
    opacity: 0.5;
    padding: 0;
    position: relative;
    transform: translateX(4px);
    width: 18px;
    z-index: 1;

    // Note: increase clickable area to 24*24
    &::after {
        // background-color: red;
        bottom: 0px;
        content: "";
        left: -3px;
        opacity: 0.5;
        position: absolute;
        right: -3px;
        top: 0px;
        z-index: 1;
    }

    &.fa-sort-up {
        &::after {
            top: -6px;
        }
    }

    &.fa-sort-down {
        &::after {
            bottom: -6px;
        }
    }

    &:active,
    &:hover {
        opacity: 1;
    }
}

.agenda-group-selector {
    background-color: $light-gray;
    border-radius: 5px;
    border: 1px solid $gray-blue;
    margin-bottom: 20px;
    margin-top: 20px;
    padding-left: 14px;
    padding-right: 14px;
    position: relative;

    &:active,
    &:hover {
        background-color: $white;
        border-color: $border-color;
        box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);

        .agenda-group-selector__head,
        .agenda-group-selector__drag-space {
            border-color: $border-color;
        }

        .agenda-group-selector__element-buttons-wrapper {
            opacity: 1;
        }
    }
}

.select-flowchart__buttons-section {
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 35px;
    transition: opacity 150ms;
    z-index: 1;
}

.select-flowchart {
    border-bottom: 2px solid $blue;
    border-top: 2px solid $blue;
    margin-bottom: 18px;
    margin-top: 18px;
    padding-top: 18px;
    position: relative;

    &:active,
    &:hover {
        .select-flowchart__buttons-section {
            opacity: 1;
        }
    }
}

.select-flowchart__top-section-heading {
    border: 0;
    color: $blue;
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: none;
}

.select-flowchart__top-section-header {
    border-bottom: 1px solid $gray-blue;
    padding: 19px 130px;
}

.select-flowchart__top-section {
    background-color: $light-gray;
    border-radius: 5px;
    border: 1px solid $gray-blue;
    padding-left: 9px;
    padding-right: 9px;
    position: relative;

    > .row {
        > .col {
            padding-left: 9px;
            padding-right: 9px;

            & + .col {
                border-left: 1px solid $gray-blue;
                padding-left: 37px;
            }
        }
    }

    .agenda-group-selector__drag-space {
        margin-top: 0;
    }
}

.select-flowchart__bottom-section {
    padding-left: 20px;
    padding-right: 20px;

    > .row {
        margin-left: -10px;
        margin-right: -10px;

        > .col {
            margin: 0 10px 18px;
        }
    }
}
