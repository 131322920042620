@import "../../../styles/inspinia/variables";

.color-picker {
    margin: 0 0 20px 0;
    max-width: 460px;

    .cp-description {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $dark-blue;
    }

    .cp-content {
        display: flex;
        align-items: flex-end;

        .cp-button,
        .cp-color {
            display: block;
            width: 36px;
            height: 36px;
            border-radius: 3px;
            background: $light-gray;
            border: 1px solid $title-border;
            overflow: hidden;
            text-indent: -300px;
            text-align: left;
            float: left;
        }

        .cp-left {
            width: 90px;

            .cp-button {
                margin: 4px 4px 0 0;
                position: relative;

                &:after {
                    position: absolute;
                    display: block;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    content: "\f055";
                    font-size: 20px;
                    line-height: 36px;
                    text-align: center;
                    color: #fff;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    text-indent: 0;
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out;
                }

                &:hover {
                    cursor: pointer;

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
        .cp-right {
            flex: 1;
            text-align: right;

            .clear,
            .cp-colors {
                display: inline-block;
                vertical-align: bottom;
            }

            .cp-color {
                margin-left: 4px;
            }

            .clear {
                width: 40px;

                .btn {
                    font-size: 30px;
                    padding: 2px 5px;

                    span {
                        margin: 0;
                    }
                }
            }
        }
    }
}
