@import "../../styles/inspinia/variables";

.client-awards-base {
    .action-buttons {
        display: flex;
    }

    .client-award-inactive {
        font-weight: bold;
        text-decoration: underline;

        &:hover {
            cursor: pointer;
        }
    }

    .balance {
        color: $blue;

        .points {
            margin-left: 20px;
        }
    }
}

.awardsbase-image-container {
    position: relative;
    width: 100%;
    width: 50px;
    height: 50px;
}

.show-cursor {
    cursor: pointer;
}

.awardsbase-title-and-image {
    display: flex;
    align-items: center;
}

.awardsbase-title {
    margin-left: 15px;
}

.awardsbase-image {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.content-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
    background-color: $blue;
    border-radius: 5px;
}