@import "../../styles/inspinia/variables";

.everyday-situation-directory {
    .directory {
        margin-right: 30px;
    }

    .directory-title {
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        color: $blue;
        margin: 10px 0 20px 0;
    }

    .directory-title ~ div {
        margin-left: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $medium-blue;
    }
}
