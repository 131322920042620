@import "../../styles/inspinia/variables";
@import "../../styles/inspinia/mixins";

.dropdown {
    .profile {
        position: relative;
        display: inline-block;
        width: 35px;
        height: 35px;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: middle;

        img {
            display: block;
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
        }
    }

    .dropdown-toggle {
        position: relative;
        padding-right: 25px;
        padding-top: 0;
        padding-bottom: 0;

        .name {
            display: inline-block;
            line-height: 37px;

            span {
                clear: both;
                display: block;
                float: left;
                line-height: 1.2;
            }

            .sv-name {
                font-weight: bold;
                font-size: 11px;
                padding: 3px 6px;
                background: $light-blue;
                color: #fff;
                border-radius: 3px;
                margin-top: 3px;
            }
        }

        &::after {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            border: none;
            content: "\f078";
            font-size: 12px;
            color: #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 20px;
            height: 18px;
            transform: rotate(0);
            transition: transform 0.2s ease-in-out;
        }
    }

    &.add-element {
        position: relative;
        display: block;
        margin-bottom: 25px;

        .dropdown-toggle {
            position: relative;
            padding-top: 12px;
            padding-bottom: 6px;
            display: block;
            background: $blue;
            color: #fff;
            font-weight: bold;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
            width: 100%;
            border-radius: 0;
            border-radius: 5px;
            padding-left: 45px;

            @include breakpoint(xlarge) {
                padding-left: 25px;
                font-size: 16px;
            }

            .name {
                line-height: 36px;
                text-align: left;
                white-space: pre-wrap;

                @include breakpoint(xlarge) {
                    text-align: center;
                    white-space: nowrap;
                }
            }

            &:after {
                right: 10px;

                @include breakpoint(xlarge) {
                    right: 20px;
                }
            }

            .fa {
                font-size: 24px;
                height: 24px;
                position: absolute;
                left: 10px;
                top: 0;
                bottom: 0;
                margin: auto;
                @include breakpoint(xlarge) {
                    left: 15px;
                }
            }
        }

        .dropdown-menu {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            border-radius: 0;
            background: $dark-blue;
            padding: 10px 0;
            width: 100%;
            margin: 0;
            border-radius: 0 0 5px 5px;

            div,
            a {
                position: relative;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                padding: 6px 20px;
                color: #fff;

                white-space: pre-wrap;

                &:after {
                    position: absolute;
                    display: block;
                    content: "";
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 3px;
                    background: $light-blue;
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out;
                }

                &:hover {
                    background: rgba(255, 255, 255, 0.1);
                    color: $light-blue;
                    cursor: pointer;

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.thin {
        display: inline-block;

        .dropdown-menu {
            margin-top: 15px;
            border-radius: 0;
            background: $dark-blue;
            padding: 10px 0;
            border-radius: 7px;
            left: auto;
            right: -16px;
            box-shadow: 0px 10px 20px rgba(13, 50, 95, 0.15);

            div,
            a {
                position: relative;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                padding: 6px 20px;
                color: #fff;

                .fa {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 12px;
                    margin-left: -6px;
                    opacity: 0.6;

                    &.fab {
                        font-family: "Font Awesome 5 Brands";
                        font-weight: 400;
                    }
                }

                &:after {
                    position: absolute;
                    display: block;
                    content: "";
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 3px;
                    background: $light-blue;
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out;
                }

                &:hover {
                    background: rgba(255, 255, 255, 0.1);
                    color: $light-blue;
                    cursor: pointer;

                    .fa {
                        opacity: 1;
                    }

                    &:after {
                        opacity: 1;
                    }
                }
            }

            &::after {
                position: absolute;
                bottom: 100%;
                right: 25px;
                display: block;
                content: "";
                width: 0;
                height: 0;
                border-left: 8px solid transparent;
                border-right: 8px solid transparent;
                border-bottom: 8px solid $dark-blue;
            }
        }

        .dropdown-toggle {
            padding: 5px 12px;
            span {
                margin: 0;
            }
            &::after {
                display: none;
            }
        }
    }

    &:hover {
        &.side-bar-node {
            & > .dropdown-toggle {
                background: $medium-blue;
            }
        }
        &.add-element {
            & > .dropdown-toggle {
                background: $light-blue;
            }
        }
    }

    &.show {
        &.side-bar-node {
            & > .dropdown-toggle {
                background: $blue;
                color: $white;
                &:after {
                    color: $white;
                    transform: rotate(180deg);
                }
            }
            & > .dropdown-menu {
                min-height: 300px;
            }
        }
        &.add-element {
            z-index: 10000;

            & > .dropdown-toggle {
                background: $light-blue;
                border-radius: 5px 5px 0 0;
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.default-agenda .table-body > *:last-of-type,
.dropdown-menu > div > *:last-of-type:not(:first-of-type):not(:nth-of-type(2)),
.dropdown-menu > div > *:nth-last-of-type(2):not(:nth-of-type(2)):not(:first-of-type) {
    .dropdown.thin .dropdown-menu {
        top: auto;
        bottom: 100%;
        margin: 0 0 15px;

        &:after {
            top: 100%;
            bottom: auto;
            border-top: 8px solid #031a36;
            border-bottom: 8px solid transparent;
        }
    }
}
