.App {
    text-align: center;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.full-screen {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.center-horizontal {
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
}

.center-dialog {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 400px;
    height: 220px;
}

.facebook-button {
    color: #fff;
    background: #3b5998;
}

.google-button {
    color: #fff;
    background: #db4437;
}

.no-hover:hover {
    color: #fff;
}

.wrapper-content {
    padding: 20px, 30px, 40px;
}

.button-danger {
    border-radius: 3px;
    background-color: #d9534f;
    border-color: #d9534f;
    color: #fff;
}

.button-warning {
    border-radius: 3px;
    background-color: #f8ac59;
    border-color: #f8ac59;
    color: #fff;
}

.button-primary {
    border-radius: 3px;
    background-color: #8bbc06;
    border-color: #8bbc06;
    color: #fff;
}
