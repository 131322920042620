@import "../../styles/inspinia/variables";

.info-bar {
    position: fixed;
    left: 220px;
    right: 0;
    bottom: 0;
    height: 56px;
    line-height: 56px;
    background: $blue;
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.5px;
    opacity: 0;
    transform: translate3d(0, 100%, 0);
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    z-index: 3;

    &.show {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    span {
        display: inline-block;
        vertical-align: middle;
        margin: 0 6px;

        &.fa {
            font-size: 26px;
        }
    }

    &.right-width {
        //right: 355px;
    }

    &.success {
        background: $success;
    }
    &.has-error {
        background: $error;
    }
    .notification-bar-button-wrapper {
        margin-left: 10px;
        margin-top: -4px;
    }
}
.side-bar-view {
    .info-bar {
        right: 355px;
    }
}
