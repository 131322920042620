.directory-image {
    .icon-and-image-container {
        position: relative;
    }
    .image-container {
        position: absolute;
        left: 20px;
        bottom: 20px;

        img {
            width: 100px;
            height: 100px;
        }

        .no-hover {
            :hover {
                pointer-events: none !important;
            }
        }
    }
}