.everyday-situation {
    @media (min-width: 1024px) {
        .input-wrapper .input-label {
            width: 350px;
        }

        .input-wrapper .input-content {
            width: calc(100% - 350px);
        }
    }
}
