@import "../../styles/inspinia/variables";

.dropdown {
    .profile {
        position: relative;
        display: inline-block;
        width: 35px;
        height: 35px;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden;
        vertical-align: middle;

        img {
            display: block;
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 50%;
        }
    }

    .dropdown-toggle {
        position: relative;
        padding-right: 25px;
        padding-top: 0;
        padding-bottom: 0;

        .name {
            display: inline-block;
            line-height: 37px;
        }

        &::after {
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            border: none;
            content: "\f078";
            font-size: 12px;
            color: #fff;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 20px;
            height: 18px;
            transform: rotate(0);
            transition: transform 0.2s ease-in-out;
        }
    }

    &.side-bar-node {
        display: block;
        overflow: hidden;
        margin: 5px -20px 0;

        & > .dropdown-toggle {
            position: relative;
            padding-top: 2px;
            padding-bottom: 2px;
            display: block;
            background: $medium-blue;
            color: #fff;
            font-weight: bold;
            font-size: 15px;
            line-height: 18px;
            text-align: left;
            width: 100%;
            border-radius: 0;
            padding-left: 10px;

            &:after {
                right: 20px;
                text-align: center;
            }
        }

        & > .dropdown-menu {
            position: relative;
            border-radius: 0;
            background: none;
            padding: 5px 0;
            width: 100%;
            box-shadow: none;
            z-index: initial;

            div.dropdown-item {
                position: relative;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                padding: 6px 20px;
                color: #fff;

                &:after {
                    position: absolute;
                    display: block;
                    content: "";
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 3px;
                    background: $light-blue;
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out;
                }

                &:hover {
                    background: rgba(255, 255, 255, 0.1);
                    color: $light-blue;
                    cursor: pointer;

                    &:after {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.show {
        &.side-bar-node {
            & > .dropdown-toggle {
                background: $blue;
                color: #fff;
                &:after {
                    color: #fff;
                }
            }
        }
    }
}
