.supervisor {
    background: $light-gray;

    .dropdown .dropdown-toggle::after {
        color: $blue;
    }
    .profile-menu {
        border-right: 1px solid rgba($blue, 0.5);
    }

    .navbar-fixed-top,
    .navbar-static-top {
        background: #ffffff;

        &,
        & * {
            color: $blue;
        }
        .navbar .logo a span,
        .navbar-right .btn,
        .dropdown .dropdown-toggle .name {
            font-weight: 500;
        }
    }
    #side-menu > li {
        background: $light-gray;
    }
    #side-menu .toggler,
    .nav > li > a,
    #side-menu .toggler::after {
        color: $blue;
    }

    .nav > li.active > a {
        color: $light-blue;
    }

    .nav {
        a:focus {
            background-color: $gray-blue !important;
        }
    }
    #side-menu .toggler.toggled {
        background: $gray-blue;
    }
    #side-menu > li.active li,
    #side-menu > li .nav-second-level {
        background: #ffffff;

        li {
            a {
                color: $dark-blue;

                i {
                    opacity: 0.3;
                }
            }
            &.active a,
            a:hover {
                color: $light-blue;
                i {
                    opacity: 1;
                    color: $light-blue;
                }
            }
        }
    }
    .subpage .page-header {
        background: $light-blue;
        border-bottom: none;

        &,
        h1,
        h2 {
            color: #ffffff;
        }

        .btn.large-icon {
            color: #fff;
            &:hover {
                color: $blue;
            }
        }
    }

    .dropdown {
        &.show {
            &.side-bar-node {
                & > .dropdown-toggle {
                    background: $medium-blue;
                    color: #fff;
                    &:after {
                        color: #fff;
                    }
                }
            }
        }
    }

    .search-form .labels .label {
        background: $light-blue;
        &:hover {
            background: rgba($light-blue, 0.6);
        }
    }
}
