.client-share-request-page {
    .content {
        text-align: center;
    }

    .avatar {
        img {
            width: 150px;
            height: 150px;
            border-radius: 75px;
            margin-bottom: 20px;
        }
    }
}
