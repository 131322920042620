@import "../../styles/inspinia/variables";

.tooltip-wrapper {
    .info-label {
        position: relative;
        display: inline-block;
    }

    .tooltip-wrapper-content {
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin: 0 auto 10px -10px;
        background: $dark-blue;
        border-radius: 6px;
        padding: 20px;
        box-sizing: border-box;
        width: auto;
        min-width: 250px;
        transform: translate3d(-50%, 0, 0);
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        display: flex;
        justify-content: center;
        align-items: center;

        &:after {
            display: block;
            content: "";
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            margin: auto;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid $dark-blue;
        }

        p,
        ul {
            margin: 0;
            padding: 0;
        }
        ul {
            padding-left: 20px;

            li {
                margin: 4px 0;
            }
        }

        &,
        & > p,
        & > ul li {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #ffffff;
        }
    }
    &:hover {
        .tooltip-wrapper-content {
            opacity: 1;
        }
    }
}
