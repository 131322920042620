@import "../../styles/inspinia/variables";
@import "../../styles/inspinia/mixins";

.selection-board {
    height: auto;
    padding-right: 80px;
    margin: 40px 0;
    position: relative;
    background: $white;
    box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
    border-radius: 5px;
    border: none;

    .edit,
    .delete {
        position: absolute;
        top: 30px;
        right: 20px;
    }

    .float-container {
        margin: 20px 0;
        overflow: hidden;
    }

    .selection-board-item {
        padding: 4px;
        width: 29%;
        float: left;
        margin: 0 2% 20px 2%;
        min-height: 280px;

        .slot.image-slot,
        .uploaded-image-container {
            margin: auto;
        }
        .uploaded-image {
            .asset-name {
                display: none;
            }
            .btn.large-icon {
                left: 50%;
                right: 0;
                margin: -5px 0 0 60px;
            }
        }
        .input-wrapper {
            margin-top: 20px;
            margin-bottom: 0;
        }
    }

    .input-wrapper input:disabled,
    .input-wrapper textarea:disabled {
        width: 100%;
    }
    .custom-label {
        font-size: 12px;
        line-height: 24px;
        padding-top: 0;
        width: 200px;
        color: $blue;
        display: inline-block;
        vertical-align: middle;
        font-weight: 700;
        align-items: center;
        display: flex;
        height: 20px;
        padding-right: 15px;
        box-sizing: border-box;
        text-transform: uppercase;
    }
}

.selection-board-list {
    .selection-board-list-element {
        border-radius: 5px;
    }

    .selection-board-list-image-container {
        position: relative;
    }

    .selection-board-image {
        border-radius: 5px;
        width: 150px;
        height: auto;
    }

    .selection-board-info {
        padding: 5px 30px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        .selection-board-title {
            font-weight: bold;
            font-size: 22px;
            line-height: 27px;
            color: $blue;
        }
        .selection-board-details {
            padding: 20px 10px;
            .selection-board-detail-part {
                padding-top: 10px;
            }
        }
    }

    .content-element {
        border: 1px solid $border-color;
        box-sizing: border-box;
        border-radius: 5px;

        &:hover {
            box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
            background: $white;
        }
    }

    .selection-board-list-new-button {
        .btn {
            margin-left: 10px;
            margin-right: 10px;
        }
    }
}
