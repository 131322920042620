@import "../../../../styles/inspinia/variables";

.searchForm-wrapper {
    margin: 20px 0 0 0;
    padding: 20px 0 20px;
    border-top: 1px solid rgba($medium-blue, 0.2);
}

.search-with-tag {
    margin-top: 20px;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    color: $dark-blue;
    opacity: 0.4;
}
