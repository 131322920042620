@import "../../../../styles/inspinia/variables";

.slot-wrapper {
    background: $light-gray;
    border: 1px solid $title-border;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 0 20px 20px 20px;
    margin: 20px 0;
}

.slot {
    &.error {
        border: 2px dashed $error;
        color: $error;

        .upload-circle {
            background: url(/images/upload-red.svg) center center no-repeat;
        }
    }
    &.disabled {
        border-color: $gray-blue;
        min-height: 108px;
    }
}
