$light-blue: #4ec1f9;
$medium-blue: #0d325f;
$dark-blue: #031a36;
$blue: #00459b;
$yellow: #e7be41;
$yellow: #e7be41;
$gray-blue: #d8e4e8;
$light-gray: #f5f5f5;
$error: #eb5757;
$place-holder: #999;
$border-color: #E4E4E4;
$title-border: #D8E4E8;
$white: #fff;
$black: #000;

// Basic Colors

$navy: #1ab394;       // Primary color
$dark-gray: #c2c2c2;  // Default color
$blue: #1c84c6;       // Success color
$lazur: #23c6c8;      // Info color
$yellow: #f8ac59;     // Warning color
$red: #ED5565;        // Danger color
$white: #FFFFFF;      // White color

// Various colors
$text-color: #676a6c;   // Body text
$gray: #f3f3f4;         // Background wrapper color
$light-gray: #D1DADE;   // Default label, badge
$label-badge-color: #5E5E5E;
$light-blue:#f3f6fb;

// Spinner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

//Basics
$basic-link-color: #337ab7;

// IBOX colors ( default panel colors)
$border-color: #e7eaec;     // IBox border
$ibox-title-bg:#ffffff;     // IBox Background header
$ibox-content-bg:#ffffff;   // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-background: url("./patterns/shattered.png");

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #2F4050;
$nav-profile-pattern: url("./patterns/header-profile.png");
$nav-text-color: #a7b1c2;

// Custom Colors
$light-blue: #4EC1F9;
$medium-blue: #0D325F;
$dark-blue: #031A36;
$blue: #00459B;
$yellow: #E7BE41;
$gray-blue: #D8E4E8;
$light-gray: #F5F5F5;
$light-gray-2: #DFDFDF;
$dark-gray: #E0E9EB;
$error: #EB5757;
$success: #91C03E;
$place-holder: #999;
$border-color: #E4E4E4;
$title-border: $gray-blue;
$dark-blue03: #9DB2C4;
