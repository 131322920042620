@import "../../styles/inspinia/variables";

.education-page {
    .section:not(:first-child) {
        margin-top: 60px;
    }
    .basic-box {
        &:hover {
            background: $white;
            border: 1px solid $gray-blue;
            box-sizing: border-box;
            box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
            border-radius: 5px;
        }
    }
    .box-title {
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        color: $blue;
    }

    .box-description {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $light-blue;
    }
    .module-tab-title {
        text-align: center;
        justify-content: center;
        align-items: center;
    }
    .sub-module-tabs {
        background-color: $white;
        display: flex;
        flex-wrap: wrap;
        margin: 30px -15px 40px;
        position: sticky;
        top: -20px;
        z-index: 1;

        &::before {
            background-color: $title-border;
            bottom: -1px;
            content: "";
            height: 1px;
            left: 15px;
            pointer-events: none;
            position: absolute;
            right: 15px;
        }

        .sub-module-tab {
            color: $medium-blue;
            display: block;
            flex: 0 0 auto;
            font-size: 11px;
            font-weight: 700;
            line-height: 14px;
            margin: 10px 15px;
            max-width: 140px;
            opacity: 0.7;
            position: relative;
            text-align: center;
            text-transform: uppercase;

            &::before {
                background-color: $light-blue;
                bottom: -11px;
                content: "";
                height: 4px;
                left: 0;
                pointer-events: none;
                position: absolute;
                right: 0;
                // transform-origin: bottom;
                // transform: scaleY(0);
                // transition: transform 300ms;
                visibility: hidden;
            }

            &:active,
            &:hover {
                opacity: 1;
            }

            &.sub-module-tab--is-active {
                color: $blue;
                opacity: 1;

                &::before {
                    // transform: scaleY(1);
                    visibility: visible;
                }
            }

            @media (min-width: 1600px) {
                max-width: 160px;
            }
        }
    }
    .sub-module-video-section {
        margin-top: 40px;
    }
}
