@import "../../../styles/inspinia/variables";
@import "../../../styles/helpers";

.select {
    position: relative;

    &,
    & * {
        user-select: none;
    }

    .select-value {
        background: #ffffff;
        border: 2px solid $blue;
        box-sizing: border-box;
        border-radius: 5px;
        color: $dark-blue;
        -moz-appearance: none;
        -webkit-appearance: none;
        padding: 10px 30px 10px 15px;
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: $medium-blue;

        &.has-error {
            border: 2px solid $error !important;
            color: $error;

            &:after {
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z' fill='#{str-replace('#{$error}', '#', '%23')}' /%3e%3c/svg%3e");
            }
        }

        .fa,
        .color-option {
            display: inline-block;
            vertical-align: middle;
            margin-right: 15px;
        }

        .color-option {
            width: 26px;
            height: 26px;
            margin-top: -8px;
            margin-bottom: -5px;
            border-radius: 3px;
        }

        &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 5px;
            width: 35px;
            content: "";
            display: block;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z' fill='#{str-replace('#{$blue}', '#', '%23')}' /%3e%3c/svg%3e");
            background-repeat: no-repeat, repeat;
            background-position: right 0.7em top 50%, 0 0;
            background-size: 0.65em auto, 100%;
            transform: rotate(0);
            transition: transform 0.2s ease-in-out;
        }

        &:hover {
            cursor: pointer;
        }

        &::-ms-expand {
            display: none;
        }
    }
    .options {
        position: absolute;
        display: none;
        top: 100%;
        left: 0;
        right: 0;
        background: #ffffff;
        border: 1px solid #e4e4e4;
        border-top-width: 0px;
        box-sizing: border-box;
        box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);

        overflow-x: auto;
        max-height: 212px;

        .option {
            padding: 10px 15px;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: $medium-blue;

            .fa,
            .color-option {
                display: inline-block;
                vertical-align: middle;
                margin-right: 15px;
            }

            .color-option {
                width: 26px;
                height: 26px;
                border-radius: 3px;
            }

            &:not(.disabled) {
                &.active,
                &:hover {
                    cursor: pointer;
                    background: #f5f5f5;
                }
            }

            &.disabled {
                color: $dark-gray;
            }
        }
    }

    &.opened {
        z-index: 1000;
        .select-value {
            &:after {
                transform: rotate(180deg);
            }
        }
        .options {
            display: block;
        }

        .select-value {
            border-radius: 5px 5px 0px 0px;
        }
    }

    &.disabled {
        .select-value {
            border: 2px solid #fff;
            &:hover {
                cursor: default;
            }
            &:after {
                display: none;
            }
        }
    }
}
.time-select {
    .select {
        .options {
            top: auto;
            bottom: 100%;
        }
    }
}
.select-text-input {
    padding: 0 !important;
    width: 100% !important;
    height: 22px !important;
    border: none !important;
    font-weight: 500 !important;

    &:focus {
        outline: none;
    }
}