@import "../../styles/inspinia/variables";

// .foldable-presentation {
// }

// .foldable-region {
// }

.foldable-region--is-disabled {
    opacity: 0.15;
    pointer-events: none;
}

.foldable-element {
    overflow: hidden;
    transition: height 250ms ease;
    will-change: height;

    > div {
        overflow: hidden;
    }
}
