@import "../../styles/inspinia/variables";

.system-event-log-page {
    .inputs {
        background: $white;
        padding: 36px 53px 30px 53px;
        box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
        border-radius: 5px;
    }
    .system-event-log-page-section-title {
        margin-left: 15px;
    }
    .system-event-log-page-inline-inputs {
        display: flex;
        justify-content: space-between;
        .select-value {
            width: 134px;
            height: 50px;
            line-height: 26px;
        }
    }
    .system-event-log-page-dummy-text {
        display: flex;
        align-items: flex-end;
        margin-bottom: 25px;
    }
    .system-event-log-page-inner-buttons {
        display: flex;
        justify-content: flex-end;
        :nth-child(2) {
            margin-left: 35px;
        }
    }
    .system-event-log-page-outer-buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 70px;
        margin-bottom: 45px;
    }
    .system-event-log-page-input-label {
        margin-bottom: 13px;
    }
}
.app-event-log-tab {
    .header-cell {
        &.data {
            min-width: 250px !important;
        }
    }
    .table-cell {
        &.data {
            min-width: 250px !important;
        }
    }
}