@import "../../styles/inspinia/variables";

.calendar-buttons {
    position: absolute;
    right: calc(100% + 100px);
    width: calc(100vw - 800px);
    text-align: center;
    height: 70px;

    display: flex;
    align-items: center;
    justify-content: center;

    .month {
        font-weight: 500;
        font-size: 20px;
        line-height: 22px;
        color: $blue;
        padding: 0 20px;
        width: 220px;
    }
}

.calendar {
    width: 100%;
    height: calc(100vh - 140px);

    .calendar-header {
        width: 100%;
        height: 65px;

        .row {
            margin: 0;
            padding: 0;
        }

        .cell {
            flex: 1;
            text-align: center;

            h3 {
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                text-align: center;
                color: $place-holder;
            }
        }
    }
    .calendar-body {
        width: 100%;
        height: calc(100vh - 205px);
        overflow-y: auto;

        .row {
            margin: 0;
            padding: 0;
        }

        .cell {
            flex: 1;
            padding: 3px;
            position: relative;
            z-index: 1;

            &:hover {
                z-index: 2;
            }

            .day {
                font-weight: normal;
                font-size: 24px;
                line-height: 30px;
                color: #000000;
                padding: 18px;
                width: 100%;
                display: inline-block;
                background: $light-gray;
                border: 2px solid $light-gray;
                height: calc((100vh - 245px) / 5);
                box-sizing: border-box;
                transition: border-color 0.2s ease-in-out;

                opacity: 0.3;

                > .count {
                    color: $medium-blue;
                }

                &.current {
                    opacity: 1;

                    &:hover {
                        border: 2px solid $blue;
                    }
                }

                &:not(.current) {
                    .menu {
                        display: none;
                    }
                }
            }

            .menu {
                position: absolute;
                top: 18px;
                right: 16px;
                transition: opacity 0.2s ease-in-out;
                opacity: 0;
                z-index: 2;
            }

            &:hover {
                .menu {
                    opacity: 1;
                }
            }

            .icon {
                width: 30px;
                height: 30px;
                position: absolute;
                display: block;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                text-align: center;
                z-index: 1;

                &:hover {
                    z-index: 10003;
                }

                .fa {
                    font-size: 30px;
                    line-height: 30px;
                    color: $light-blue;

                    &.success {
                        color: $success;
                    }
                }
            }

            .today {
                background: #fff;
                border: 2px solid $light-blue;

                .icon {
                    .fa {
                        color: #fff;
                        &.success {
                            color: $success;
                        }
                    }
                }
            }
        }
    }
}
