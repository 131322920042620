@import "../../styles/inspinia/variables";

.dashboard {
    &.left-side {
        padding: 30px 15px;
    }

    .dashboard-client {
        font-size: 16px;
        font-weight: bold;
        height: 100%;
        width: 742px;

        > .row {
            height: 100%;
        }
    }

    .dashboard-client,
    .dashboard-multi-chart {
        box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
        border-radius: 5px;
    }
    .dashboard-client-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $light-gray;
        padding-top: 25px;
        padding-bottom: 20px;
        height: 100%;
        width: 310px;
    }
    .dashboard-client-inner__image {
        border-radius: 50%;
        height: 188px;
        margin-bottom: 15px;
        width: 188px;
    }
    .scroll-buttons {
        position: relative;
        padding-left: 20 + 9 + 20px;
        padding-right: 20 + 9 + 20px;
        width: 100%;

        button {
            color: $blue;

            &.scroll-icon {
                background-color: transparent;
                border: 0;
                cursor: pointer;
                font-size: 18px;
                padding: 20px 18px;
                position: absolute;
                top: -15px;

                &.fa-chevron-left {
                    left: 0;
                }

                &.fa-chevron-right {
                    right: 0;
                }
            }
        }
    }
    .scroll-buttons__text {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        color: $blue;
        display: -webkit-box;
        font-size: 22px;
        font-weight: bold;
        line-height: 27px;
        height: 3 * 27px;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
    }
    .log-count {
        color: $dark-blue;
        margin-top: 7px;
        margin-bottom: 40px;
    }
    .index-count {
        line-height: 15px;

        &.small-text {
            > span {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
    .dashboard-chart__wrapper {
        padding: 30px 20px 5px 5px;
    }
    .dashboard-chart-title,
    .dashboard-error-title,
    .dashboard-error-title-supporter {
        font-size: 18px;
        text-transform: uppercase;
    }
    .dashboard-error-title-supporter {
        font-weight: 500 !important;
    }
    .dashboard-admin-error-title,
    .dashboard-supervisor-error-title {
        text-transform: none;
    }
    .dashboard-error,
    .dashboard-supervisor-error {
        border: 1px solid $gray-blue;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 30px;
        height: 100%;

        p:nth-child(1) {
            font-size: 22px;
            color: $blue;
            font-weight: bold;
        }

        p {
            margin-bottom: 45px;
        }

        p:last-child {
            margin-bottom: 0px;
        }
    }
    .dashboard-supervisor-error {
        display: flex;
        justify-content: center;
        background-color: $light-gray;
        margin-bottom: 30px;
    }
    .dashboard-error-text {
        color: $dark-blue;
    }
    .dashboard-app {
        border: 1px solid $gray-blue;
        border-radius: 5px;
        padding: 30px 30px 40px;

        .app-link {
            width: 445px;
        }
    }
    .dashboard-no-clients {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 100px;
    }
    .dashboard-admin-bottom-row-error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 1px solid $gray-blue;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 30px;
        background-color: $light-gray;
        height: 100%;

        p:nth-child(1) {
            font-size: 22px;
            color: $blue;
            font-weight: bold;
        }

        p {
            margin-bottom: 45px;
        }
        p:last-child {
            margin-bottom: 0px;
        }
    }
    .dashboard-admin-bottom-row-app,
    .dashboard-supervisor-app {
        box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
        border-radius: 5px;
        padding: 30px;
        justify-content: center;
        height: 100%;
    }
    .dashboard-app-text {
        color: $dark-blue;
        font-size: 16px;
        font-weight: 500;
    }
    .dashboard-app-icons {
        a {
            display: inline-block;
        }
    }
    .dashboard-admin-chart-title {
        font-weight: 500;
        font-size: 16px;
        color: $blue;
        text-align: center;
        margin-top: 37px;
        margin-bottom: 30px;
    }
    .dashboard-multi-chart {
        .recharts-wrapper {
            margin: 0 auto;
        }
        .dashboard-multi-chart-inner:nth-child(1) {
            border-right: 1px solid $gray-blue;
        }
    }
    .dashboard-content-share-request-table-container {
        padding: 26px;
        background: $white;
        box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
        border-radius: 5px;
        height: 100%;
        .row {
            margin-left: 0px;
            margin-right: 0px;
        }
    }
    .dashboard-loading-wrapper {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;

        .loading {
            &.input {
                .loading-animation {
                    bottom: 0;
                    left: 0;
                    position: relative;
                    right: 0;
                    top: 0;
                }
            }
        }
    }
}
