@import "../../styles/inspinia/variables";

.search-form {
    .input-group-btn {
        position: absolute;
        top: 6px;
        right: 0;
        margin: auto;
        height: 30px;
        z-index: 3;
    }

    input {
        background-color: #ffffff;
        border: 1px solid $gray-blue;
        box-sizing: border-box;
        border-radius: 5px !important;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        padding: 14px;
    }

    .labels {
        margin-top: 10px;

        .label {
            margin: 10px 10px 0 0;
            padding: 2px 2px 1px 10px;
            display: inline-block;
            background: #ffc925;
            border-radius: 5px;
            color: $dark-blue;

            &:hover {
                background: #ffd75c;
            }

            .btn {
                padding: 2px 5px;
                margin-left: 5px;

                &:hover {
                    color: $dark-blue;
                }

                span {
                    margin: 0;
                }
            }
        }
    }
}

.form-control:focus,
.single-line:focus {
    border-color: $light-blue;
}
