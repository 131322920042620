@import "../../styles/inspinia/variables";
@import "../../styles/inspinia/mixins";

.btn {
    span.right {
        margin: 0 0 0 10px;
    }
    span.left {
        margin: 0 10px 0 0;
    }
}

.btn {
    letter-spacing: 0.5px;

    .fa.left {
        margin-right: 10px;
        margin-left: 0;
    }

    &.btn-outline,
    &.btn-primary,
    &.btn-danger {
        min-width: 120px;
        @include breakpoint(xlarge) {
            min-width: 160px;
        }
        transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out, background-color 0.2s ease-in-out !important;
    }

    &.disabled:hover,
    &:disabled:hover {
        cursor: not-allowed;
    }

    &.no-margin {
        margin: 0 !important;
    }

    &.btn-primary {
        background-color: $blue;
        border: 2px solid $blue;
        border-radius: 5px;
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        text-transform: uppercase;
        text-align: center;
        padding: 10px 25px;

        &.small {
            font-size: 12px;
            line-height: 16px;
            padding: 5px 15px;
            margin-top: 10px;
            max-width: 0;
            width: auto;
            min-width: fit-content;
        }

        @include breakpoint(xlarge) {
            font-size: 15px;
            line-height: 20px;
            text-transform: uppercase;
            text-align: center;
            padding: 10px 35px;
        }

        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):hover {
            background-color: $light-blue;
            border-color: $light-blue;
            box-shadow: none;
        }

        &:disabled,
        &.disabled {
            background-color: $blue;
            border-color: $blue;
        }
    }

    &.btn-danger {
        background-color: $red;
        border: 2px solid $red;
        border-radius: 5px;
        font-weight: 600;
        font-size: 16px;
        line-height: 18px;
        text-transform: uppercase;
        text-align: center;
        padding: 10px 25px;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;

        &.small {
            font-size: 12px;
            line-height: 16px;
            padding: 5px 15px;
            margin-top: 10px;
            max-width: 0;
            width: auto;
            min-width: fit-content;
        }

        @include breakpoint(xlarge) {
            font-size: 15px;
            line-height: 20px;
            text-transform: uppercase;
            text-align: center;
            padding: 10px 35px;
        }

        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):hover {
            background-color: $red;
            border-color: $red;
            box-shadow: none;
            opacity: 0.8;
        }

        &:disabled,
        &.disabled {
            background-color: $red;
            border-color: $red;
            opacity: 0.8;
        }
    }

    &.btn-primary.btn-outline,
    &.btn-outline {
        background-color: #fff;
        border-color: $blue;
        font-weight: 700;
        color: $blue;
        border: 2px solid $blue;

        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):hover {
            background-color: #fff;
            border-color: $light-blue;
            color: $light-blue;
            box-shadow: none;
        }

        &.btn-white {
            color: #fff;
            border-color: #fff;
            background: none;

            &:not(:disabled):not(.disabled):focus,
            &:not(:disabled):not(.disabled):active,
            &:not(:disabled):not(.disabled):hover {
                background: #fff;
                border-color: #fff;
                color: $error;
            }
        }

        &:disabled,
        &.disabled {
            background-color: #fff;
            color: $blue;
            border-color: $blue;
        }

        &.small {
            font-size: 12px;
            line-height: 16px;
            padding: 5px 15px;
            margin-top: 10px;
            max-width: 0;
            width: auto;
            min-width: fit-content;
            text-transform: uppercase;
        }
    }

    &.large-icon {
        color: $blue;
        font-size: 18px;
        line-height: 20px;

        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):hover {
            color: $light-blue;
            box-shadow: none;
        }
    }

    &.save {
        background: $blue;
        color: #fff;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        border-radius: 5px;
        padding: 6px 12px;
        display: inline-block;

        span {
            margin-right: 12px;
            font-size: 18px;
        }
        &:not(:disabled):not(.disabled):focus,
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled):hover {
            color: #fff;
            background: $light-blue;
        }
    }

    &.saved {
        background: $success;
        color: #fff;
        font-weight: 600;
        font-size: 15px;
        line-height: 19px;
        border-radius: 5px;
        padding: 6px 12px;
        display: inline-block;

    span {
      margin-right: 12px;
      font-size: 18px;
    }
    &:not(:disabled):not(.disabled):focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):hover {
      color: #fff;
      background: $success;
    }
  }
  &.create-directory {
    padding: 10px 0 !important;
  }
}
