@import "../../styles/inspinia/variables";
@import "../../styles/inspinia/mixins";

.search-form-wrapper {
    margin: 0 0 20px 0;
    padding: 20px 0 20px;
    border-bottom: 1px solid rgba($medium-blue, 0.2);
}

.content-share-request-table {
    .table-title-row {
        border-bottom: 1px solid rgba($medium-blue, 0.2);
        margin: 0 0 0.5rem 0;
        padding-bottom: 0.75rem;
        background-color: $white;
        padding-top: 20px;
        position: sticky;
        top: 0;
        z-index: 1;
        h2 {
            border-bottom: none;
            margin: 0;
            padding: 0;
            line-height: 45px;
        }
        .search-form {
            input {
                border-radius: 5px;
            }
        }
    }

    .table-image-name {
        display: flex;
        align-items: center;
    }

    .table-image-container {
        flex-basis: 30px;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        overflow: hidden;
        position: relative;
        margin-right: 15px;
        flex-grow: 0;
        flex-shrink: 0;

        @include breakpoint(xlarge) {
            width: 50px;
            height: 50px;
            flex-basis: 50px;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            object-fit: cover;
            object-position: center;
        }
    }
}
