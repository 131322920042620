@import "../../styles/inspinia/variables";

.modal-layer {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: -1;

    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;

    .modal-window {
        position: absolute;
        background: #fff;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -40%, 0);
        width: 800px;
        border-radius: 5px;
        transition: transform 0.3s ease-in-out;

        &.alert {
            width: 600px;
            text-align: center;
            padding: 40px;
            img {
                margin-bottom: 40px;
                width: 90px;
                height: 90px;
            }
            p {
                margin: 0;
                padding: 0;
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    .modal-window.alert p {
        color: $blue;
    }
    &.success .modal-window.alert {
        p {
            color: $success;
        }
    }
    &.has-error .modal-window.alert {
        p {
            color: $error;
        }
    }

    hr {
        background: $gray-blue;
        height: 1px;
        border: 0;
        padding: 0;
        width: 100%;
        margin: 20px 0;
    }

    .modal-header {
        padding: 20px;
        background: $gray;

        h3 {
            margin: 0;
            padding: 0;
            font-weight: 400;
            font-size: 26px;
            line-height: 32px;
            color: $blue;

            .fa {
                margin-right: 15px;
            }
        }

        .close {
            font-size: 30px;
            opacity: 1;
        }
    }

    .modal-content {
        padding: 20px;
        box-shadow: none;
    }

    .modal-content--scrollable {
        max-height: 590px;
        overflow: auto;
        padding: 30px;

        .tag-input-group {
            &.open {
                margin-bottom: 30px;
            }
        }

        .select {
            &.opened {
                .options {
                    margin-bottom: 30px;
                }
            }
        }
    }

    .buttons {
        margin: 0;
    }

    &.show {
        animation: fadeIn ease-in-out 0.3s forwards;
        pointer-events: all;
        z-index: 100000;

        .modal-window {
            animation: modalWindowSlideUp ease-in-out 0.3s forwards;
        }

        &.modal-layer--is-closing {
            animation: fadeOut ease-in-out 0.3s forwards;

            .modal-window {
                animation: modalWindowSlideDown ease-in-out 0.3s forwards;
            }
        }
    }
}
.max-dialog-height {
    max-height: calc(100vh - 300px);
    padding: 10px 0;
    overflow-y: auto;
    overflow-x: hidden;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes modalWindowSlideUp {
    0% {
        transform: translate3d(-50%, -40%, 0);
    }
    100% {
        transform: translate3d(-50%, -50%, 0);
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes modalWindowSlideDown {
    0% {
        transform: translate3d(-50%, -50%, 0);
    }
    100% {
        transform: translate3d(-50%, -40%, 0);
    }
}
