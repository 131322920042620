@import "../../styles/inspinia/variables";
@import "../../styles/inspinia/mixins";

.sortable-list {
    .content-element {
        background: $light-gray;

        :hover {
            background-color: $white;
        }

        > div {
            padding: 10px;
        }
    }

    .grabbed {
        .content-element {
            box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
        }
    }

    .drag-space {
        position: absolute;
        z-index: 2;
        width: 20px;
        height: 30px;
        right: 20px;
        bottom: 20px;

        .up {
            display: block;
            position: absolute;
            top: 2px;
            left: 0;
            right: 0;
            width: 0;
            height: 0;
            margin: auto;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 7px solid $dark-blue;
            opacity: 0.5;
            transition: opacity 0.2s ease-in-out;
        }
        .down {
            display: block;
            position: absolute;
            bottom: 2px;
            left: 0;
            right: 0;
            width: 0;
            height: 0;
            margin: auto;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-top: 7px solid $dark-blue;
            opacity: 0.5;
            transition: opacity 0.2s ease-in-out;
        }

        .down,
        .up {
            &:hover {
                cursor: pointer;
                opacity: 1;
            }
        }
    }
}
