@mixin breakpoint($class) {
    @if $class == small {
        @media (max-width: 640px) {
            @content;
        }
    } @else if $class == medium {
        @media (min-width: 641px) {
            @content;
        }
    } @else if $class == tablet {
        @media (min-width: 768px) {
            @content;
        }
    } @else if $class == large {
        @media (min-width: 1024px) {
            @content;
        }
    } @else if $class == xlarge {
        @media (min-width: 1440px) {
            @content;
        }
    } @else {
        @warn "Breakpoint mixin supports: small, medium, tablet, large, xlarge";
    }
}
