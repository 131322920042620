@import "../../styles/inspinia/variables";
@import "../../styles/inspinia/mixins";

.table {
    position: relative;

    .empty {
        padding: 100px 20px 50px 20px;
        text-align: center;

        p {
            font-weight: 800;
        }
    }

    .table-header {
        color: $light-blue;
        font-weight: bold;
        font-size: 11px;
        line-height: 14px;
        text-transform: uppercase;
    }
    .table-body {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $dark-blue;

        @include breakpoint(xlarge) {
            font-size: 18px;
        }
    }
    .table-row.clickable {
        cursor: pointer;
        &:hover {
            background-color: $light-gray;
        }
    }

    .table-row,
    .table-header {
        display: flex;
        position: relative;

        .header-cell {
            user-select: none;

            .fa,
            .title.sortable {
                &:hover {
                    cursor: pointer;
                }
            }
        }

        .table-cell,
        .header-cell {
            flex: 1;
            padding: 8px;
            align-items: center;
            display: flex;

            flex-grow: 0;
            flex-shrink: 0;

            width: 90px;
            flex-basis: 90px;
            // Overflow hiding
            // overflow: hidden;

            @include breakpoint(xlarge) {
                flex-basis: 140px;
                width: 14px;
            }

            .title,
            .question {
                margin-right: 5px;
                font-size: 11px;
            }

            .question-line {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            &.title,
            &.question {
                word-break: break-all;
            }

            &.text-right {
                justify-content: flex-end;
            }

            &.actions,
            &.remove,
            &.delete {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 60px;
                width: 60px;
                text-align: center;
                align-items: center;
                justify-content: center;

                @include breakpoint(xlarge) {
                    flex-basis: 80px;
                    width: 80px;
                }

                button {
                    span {
                        margin: 0;
                    }
                }
            }

            &.numberOfSteps,
            &.info {
                flex-basis: 40px;
                width: 40px;
                align-items: center;
                justify-content: center;
                text-align: center;

                @include breakpoint(xlarge) {
                    flex-basis: 80px;
                    width: 80px;
                }
            }
            &.isDisabled {
                max-width: 60px;
            }
            &.event,
            &.title,
            &.value,
            &.id,
            &.name,
            &.type,
            &.device,
            &.email,
            &.extId,
            &.libraryContentTitle,
            &.question {
                //flex-basis: calc((100% - 160px)/2);
                //width: calc((100% - 160px)/2);
                flex: 1;
            }
            &.libraryContentCreatedAt,
            &.libraryContentUpdatedAt,
            &.createdAt {
                flex-basis: 120px;
                width: 120px;
                @include breakpoint(xlarge) {
                    flex-basis: 140px;
                    width: 140px;
                }
                .table-header {
                    color: $light-blue;
                    font-weight: bold;
                    font-size: 11px;
                    line-height: 14px;
                    text-transform: uppercase;
                }
                .table-body {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    color: $dark-blue;
                }
            }
            &.status {
                flex-basis: 120px;
                width: 120px;
                @include breakpoint(xlarge) {
                    flex-basis: 140px;
                    width: 140px;
                }
            }
            &.select {
                flex-basis: 50px;
                .checkbox-toggle {
                    margin-top: 0;
                }
            }
        }
    }
    .table-row {
        position: relative;
        border-top: 1px solid $border-color;

        &.action-button-container {
            padding: 10px 0 20px;

            .table-cell.text-right {
                flex: 1;
                width: 100%;
            }
        }

        &.action-button-container,
        &:first-of-type {
            border: none;
        }
    }
    .table-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        margin: 20px;
        font-size: 14px;

        .pager {
            span.hidden {
                background: white;
                border-top: 1px solid $border-color;
                border-bottom: 1px solid $border-color;
                display: inline-block;
                font-weight: 400;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                user-select: none;
                font-size: 1rem;
                line-height: 1.5;
                padding-left: 10px;
                padding-right: 10px;
                letter-spacing: 1px;
                width: 36px;
                box-sizing: border-box;
            }
        }

        .btn {
            color: $blue;
            min-width: 36px;
            text-align: center;
            padding-left: 10px;
            padding-right: 10px;

            &:not(.disabled):hover {
                border-color: $light-blue;
                background-color: $light-gray;
            }

            &.active {
                &,
                &:hover {
                    background: $light-blue;
                    color: #fff;
                    font-weight: 700;
                    box-shadow: none;
                }
            }
            &.disabled {
                &,
                &:hover {
                    background-color: rgba($light-gray, 0.5);
                    color: $place-holder;
                }
            }
            span {
                margin: 0;
            }
        }
    }
    .sortable-list {
        .content-element {
            background: inherit;

            div {
                padding: 0;
            }
        }
    }
}
.content-library-table {
    .table .table-body {
        min-height: 400px;
    }
}

.dialog-asset-table {
    .table .table-body {
        min-height: unset;
    }
}

.table-top {
    .checkbox-toggle {
        margin-top: 0;
    }
    .tooltip-wrapper {
        padding: 5px 0;
        .tooltip-wrapper-content {
            min-width: unset;
            padding: 6px 18px;
        }
    }
}
