@import "src/styles/inspinia/variables";

.card-notification {
    padding: 13px 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    .icon {
        flex: 1;
        min-width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            line-height: 46px;
            font-size: 46px;
        }
        .text-error {
            color: $error;
        }
        .text-warning {
            color: $yellow;
        }
        .text-success {
            color: $success !important;
        }
    }
    .content {
        flex: 9;
        border-left: 1px solid $gray-blue;
        padding: 10px 20px;
        .title {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: $blue;
        }
        .description {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: $medium-blue;
            margin-top: 10px;
        }
    }
}
.card-warning {
    padding: 30px 20px;
}
.grid-x {
    justify-content: space-between;
    .btn-outline {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: uppercase;
        padding: 10px 35px;
    }
    .btn-primary {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
}
.card-warning,
.dialog-content {
    .title {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: $blue;
    }
    .description {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: $medium-blue;
        margin-top: 10px;
    }
}
