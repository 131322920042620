@import "../../../../../styles/inspinia/variables";

.sidebar-content-element {
    position: relative;
    margin: 0;
    padding: 10px 20px;
    //background: $light-gray;
    //border: 1px solid $gray-blue;
    box-sizing: border-box;
    //border-radius: 5px;
    display: flex;
    transition: all 0.2s ease-in-out;

    &:hover {
        cursor: pointer;
        background: #ffffff;
        //box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
    }

    .content-image {
        position: relative;
        display: flex;
        width: 50px;
        height: 50px;
    
        &:nth-of-type(2) {
            margin-left: 10px;
        }

        .sidebar-content-element-indicator {
            position: absolute;
            top: -10px;
            right: -10px;
            z-index: 2;
        }

        .image-container {
            position: relative;
            border-radius: 5px;
            width: 50px;
            height: 50px;
            overflow: hidden;
    
            &:nth-of-type(2) {
                margin-left: 10px;
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                display: block;
                border-radius: 5px;
            }
        }
        .add-action {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            background: #a3b2c3;
            border-radius: 5px;
            width: 50px;
            height: 50px;
            overflow: hidden;

            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            z-index: 1;

            &:hover {
                opacity: 1;
            }

            button {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                background: none;
                border: none;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .content-content {
        flex: 1;
        padding-left: 10px;

        .content-title {
            position: relative;

            padding-right: 20px;

            h3 {
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                color: $blue;
                margin: 5px 0 5px 0;
                padding: 0;
            }
            p {
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: $blue;
                padding: 0;
                margin: 0;
            }
            .buttons {
                position: absolute;
                top: 0;
                bottom: 0;
                right: -5px;
                height: 36px;
                margin: auto;

                .btn {
                    padding: 5px 10px;
                }
            }
        }
    }
}

.clone {
    background: #ffffff;

    ~ div {
        transform: none !important;
    }
}
.right-side {
    [data-react-beautiful-dnd-placeholder] {
        display: none !important;
    }
    .scrolled-content {
        height: calc(100vh - 300px);
        margin: 0 -20px -20px -20px;
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

.sidebar-content-element-container {
    &.grabbed {
        border-radius: 5px;
        box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
        cursor: grabbing;
    }
    &.disabled {
        .sidebar-content-element {
            cursor: not-allowed;
        }
        .content-image,
        .content-title h3,
        .content-title p {
            opacity: 0.4;
        }
        .content-title .buttons {
            cursor: pointer;
        }
    }
}
.droppable-wrapper {
    background: $white;
}
