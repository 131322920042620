@import "../../styles/inspinia/variables";

.foldable-section {
    background-color: $white;
    border-radius: 5px;
    border: 1px solid $gray-blue;
    box-shadow: 0px 10px 20px rgba(3, 26, 54, 0.15);
    margin-bottom: 30px;
    overflow: hidden;
}

.foldable-section__header-section {
    color: $blue;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    padding: 14px 49px 14px 19px;
    position: relative;
    z-index: 1;
}

.foldable-section__header-icon-wrapper {
    align-items: center;
    background-image: url(/images/FoldableArrow.svg);
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    height: 50px;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center;
    transform: rotate(0);
    transition: transform 150ms;
    width: 50px;
}

.foldable-section__header-icon-wrapper--is-opened {
    transform: rotate(180deg);
}

.foldable-section__toggle-button {
    background-color: transparent;
    border: 0;
    bottom: 0;
    cursor: pointer;
    left: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.foldable-section__body {
    padding: 0 49px 19px 19px;

    p {
        margin-bottom: 20px;
    }

    p {
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
